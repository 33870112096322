import { useTranslation } from 'react-i18next';

export function NoLinkPlusTemplate() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-[400px]">
      <p className="text-[15px]">{t('organizer.empty.linkplus')}</p>
    </div>
  );
}

export default NoLinkPlusTemplate;
