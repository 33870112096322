import { Editor } from '@daouoffice/ui/lib/foundation/Editor';
import * as Popover from '@daouoffice/ui/lib/foundation/Popover';

import {
  CheckIcon,
  SwatchIcon,
} from '@daouoffice/ui/lib/icons/heroicons/24/outline';
import { clsx } from 'clsx';
import { Dispatch, SetStateAction, useState } from 'react';
import { useMemoContext } from '../../Context';
import { IconButton } from '@daouoffice/ui';
import type { MemoColors } from '../../types';
import { MemoColorClassNames } from '../../constants';

interface Props {
  editor: Editor | null;
  setToolbarShow: Dispatch<SetStateAction<boolean>>;
}
function MemoToolbar({ editor, setToolbarShow }: Props) {
  const [paletteOpen, setPaletteOpen] = useState(false);

  const {
    memoContext: { color },
    setMemoContext,
  } = useMemoContext();

  const handleOnChangeBgColor = (changedColor: MemoColors) => {
    if (color === changedColor) return;

    setMemoContext((prev) => ({
      ...prev,
      requiredSave: true,
      color: changedColor,
    }));
  };

  if (!editor) {
    return null;
  }

  return (
    <>
      <IconButton title="toolbar-open" onClick={() => setToolbarShow(true)}>
        <span>Aa</span>
      </IconButton>
      <IconButton
        title="task"
        onClick={() => editor.chain().focus().toggleTaskList().run()}
      >
        <CheckIcon
          width={32}
          height={32}
          className={clsx({
            'bg-[#979797] text-white': editor.isActive('taskList'),
          })}
        />
      </IconButton>

      <Popover.Root open={paletteOpen} onOpenChange={setPaletteOpen}>
        <Popover.Trigger>
          <IconButton title="palette" as="div">
            <SwatchIcon
              width={32}
              height={32}
              className={clsx({
                'bg-[#979797] text-white': paletteOpen,
              })}
            />
          </IconButton>
        </Popover.Trigger>
        <Popover.Content side="bottom" align="start" isPortal className="z-50">
          <div className="z-50 flex justify-around p-4 gap-3 bg-white rounded-md">
            {Object.entries(MemoColorClassNames).map(
              ([colorName, colorClass]) => (
                <button
                  key={colorName}
                  className={clsx(
                    'rounded-full w-8 h-8 flex justify-center items-center',
                    colorClass,
                  )}
                  onClick={() => handleOnChangeBgColor(colorName as MemoColors)}
                >
                  {colorName === color && (
                    <span className="font-bold">{'✓'}</span>
                  )}
                </button>
              ),
            )}
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}

export default MemoToolbar;
