import { ChevronRightIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useAtom, useAtomValue } from 'jotai';
import { appsAtom } from '../../../store/appsAtom';
import { selectedAppIdAtom } from '../../../store/selectedAppIdAtom';
import { getMenuNames } from '../../../utils/menuNameUtils';
import { FavoriteButton } from './FavoriteButton';

export function Header() {
  const [{ data: appList }] = useAtom(appsAtom);
  const selectedAppId = useAtomValue(selectedAppIdAtom);

  const menuNames = getMenuNames(selectedAppId, appList ?? []);

  const titleBlock = menuNames && (
    <>
      <h2 className="txt text-3xl">{menuNames.mainCategoryName}</h2>
      <ChevronRightIcon />
      {menuNames.subCategoryName !== '' && (
        <>
          <h2 className="txt text-3xl">{menuNames.subCategoryName}</h2>
          <ChevronRightIcon />
        </>
      )}
      <span className="txt text-3xl font-medium">{menuNames.appName}</span>
    </>
  );

  return (
    selectedAppId && (
      <div className="-tracking-[.08em] flex items-center pl-[75px] pb-[8px] pt-[42px]">
        {titleBlock}
        <FavoriteButton />
      </div>
    )
  );
}

export default Header;
