import { PropsWithChildren } from 'react';
import { ShortcutItemInfo, SizeType } from '../types';
import LinkItem from '../LinkItem';
import LinkButton from '../LinkButton';

export interface Props extends ShortcutItemInfo {
  className: string;
}

const getActionItemSize = (sizeType: SizeType) => {
  if (sizeType === 'tiny') {
    return [16, 'ic_small', 'small'];
  } else if (sizeType === 'small') {
    return [18, 'ic_small', 'small'];
  } else if (sizeType === 'medium') {
    return [24, 'ic_medium', 'medium'];
  } else if (sizeType === 'large') {
    return [36, 'ic_large', 'large'];
  } else {
    return [48, 'ic_xl', undefined];
  }
};

function ShortcutListItem({
  uid,
  name,
  size,
  icon,
  url,
  itemType,
  appType,
  onClick,
}: PropsWithChildren<Props>) {
  const [sizeNumber] = getActionItemSize(size);

  return (
    <LinkButton
      id={uid}
      name={name}
      url={url}
      itemType={itemType}
      appType={appType}
      onClick={onClick}
    >
      <LinkItem
        id={uid}
        name={name}
        size={sizeNumber as number}
        faviconUrl={icon}
      />
    </LinkButton>
  );
}

export default ShortcutListItem;
