export const searchHilight = (keyword: string, content: string) => {
  try {
    const escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedKeyword, 'gi');

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    const highlightTextNodes = (node: Node): void => {
      if (node.nodeType === Node.TEXT_NODE) {
        const textNode = node as Text;
        const matches = Array.from(textNode.textContent?.matchAll(regex) || []);
        if (matches.length > 0 && textNode.textContent) {
          const fragments: (Text | HTMLSpanElement)[] = [];
          let lastIndex = 0;
          matches.forEach((match) => {
            if (match.index !== undefined) {
              fragments.push(
                document.createTextNode(
                  textNode.textContent!.slice(lastIndex, match.index),
                ),
              );
              const span = document.createElement('span');
              span.className = 'text-[#7EBCC5] font-bold';
              span.textContent = match[0];
              fragments.push(span);
              lastIndex = match.index + match[0].length;
            }
          });
          fragments.push(
            document.createTextNode(textNode.textContent.slice(lastIndex)),
          );
          textNode.replaceWith(...fragments);
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        node.childNodes.forEach((child) => highlightTextNodes(child));
      }
    };

    doc.body.childNodes.forEach((child) => highlightTextNodes(child));

    const serializer = new XMLSerializer();
    const contentWithHighlight = serializer.serializeToString(doc.body);

    return contentWithHighlight;
  } catch (error) {
    console.log(error);
    return content;
  }
};
