import { fetch } from '../../fetch/client';

export type Platform = 'EMPLOYEE' | 'BUSINESS' | 'SETTING' | 'NONE';

const url = (platform: Platform) =>
  `/api/portal/common/menu/user/${platform}/favorite`;

export function addFavoriteMenu(platform: Platform, menuID: number) {
  return fetch(url(platform), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: menuID.toString(), // 추후 body에 대한 타입 정의 필요
  });
}

export async function removeFavoriteMenu(platform: Platform, menuID: number) {
  return fetch(url(platform), {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: menuID.toString(), // 추후 body에 대한 타입 정의 필요
  });
}
