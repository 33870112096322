import { useSuspenseQuery } from '@tanstack/react-query';
import MemoList from '../MemoList';
import * as getMemoSearch from '../apis/getMemoSearch';
import NoMemoSearchResultTemplate from './NoDataTemplate/NoMemoSearchResultTemplate';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function SearchResultList() {
  const { memoContext } = useMemoContext();

  const { t } = useTranslation();

  const {
    data: { data: memoListInfo },
    error,
  } = useSuspenseQuery({
    queryKey: [getMemoSearch.QUERY_KEY, memoContext.keyword],
    queryFn: async () => await getMemoSearch.getMemoSearch(memoContext.keyword),
  });

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  return (
    <div>
      <div className="text-gray-400 my-2">
        <span>{t('organizer.memo.search.result')}</span>
        <span className="text-black font-medium">{` ${memoListInfo.count}`}</span>
      </div>
      {memoListInfo?.memoList.length > 0 ? (
        <MemoList memoList={memoListInfo.memoList} />
      ) : (
        <NoMemoSearchResultTemplate />
      )}
    </div>
  );
}

export default SearchResultList;
