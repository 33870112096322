import * as React from 'react';
import { useAtom } from 'jotai';
import { useDebounce } from '@daouoffice/ui/lib/hooks';
import { keywordSearchedFuseResult } from '../../../../../lib/search';
import { appsAtom } from '../../../store/appsAtom';
import { ConfigMenuInfo } from '../../../types';

export interface IContextValues {
  readonly searchKeyword: string;
  readonly searchedMenuInfo: ConfigMenuInfo[];
}

export interface IContextActions {
  onChangeSearchKeyword: (keyword: string) => void;
}

export const ValuesContext = React.createContext<IContextValues>({
  searchKeyword: '',
  searchedMenuInfo: [],
});

export const ActionsContext = React.createContext<IContextActions>({
  onChangeSearchKeyword: () => undefined,
});

export function Provider({ children }: { children: React.ReactNode }) {
  const [{ data }] = useAtom(appsAtom);
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [searchedMenuInfo, setSearchedMenuInfo] = React.useState<
    ConfigMenuInfo[]
  >([]);

  const debouncedHandleSearch = useDebounce((value: string) => {
    setSearchKeyword(value);
  }, 250);

  const onChangeSearchKeyword = (keyword: string) => {
    debouncedHandleSearch(keyword);
  };

  React.useEffect(() => {
    if (searchKeyword === '' || !data) {
      setSearchedMenuInfo([]);
      return;
    }

    const searchedMenu = keywordSearchedFuseResult(data, searchKeyword, [
      'name',
      'childList.name',
      'childList.childList.name',
    ])
      .map((fuseResult) => {
        const matchedKeywords: Set<string> = new Set();

        if (fuseResult.matches)
          fuseResult.matches.forEach((match) => {
            if (match.value) matchedKeywords.add(match.value);
          });

        return matchFilteredConfigMenuInfo(fuseResult.item, matchedKeywords);
      })
      .filter((item): item is ConfigMenuInfo => item !== undefined);
    setSearchedMenuInfo(searchedMenu);
  }, [data, searchKeyword]);

  const contextValues = {
    searchKeyword,
    searchedMenuInfo,
  };

  const contextActions = {
    onChangeSearchKeyword,
  };

  return (
    <ActionsContext.Provider value={contextActions}>
      <ValuesContext.Provider value={contextValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useAppMenuValuesContext = () => React.useContext(ValuesContext);
export const useAppMenuActionsContext = () => React.useContext(ActionsContext);

export const useAppMenuContext = () => {
  const values = useAppMenuValuesContext();
  const actions = useAppMenuActionsContext();

  return {
    ...values,
    ...actions,
  };
};

const matchFilteredConfigMenuInfo = (
  item: ConfigMenuInfo,
  matchKeywords: Set<string>,
) => {
  if (
    (item.childList &&
      item.childList.length === 0 &&
      matchKeywords.has(item.name)) ||
    matchKeywords.has(item.name)
  ) {
    return item;
  }

  if (!item.childList) return;

  const childs: ConfigMenuInfo[] = item.childList
    .map((child) => {
      return matchFilteredConfigMenuInfo(child, matchKeywords);
    })
    .filter((item): item is ConfigMenuInfo => item !== undefined);

  if (childs && childs.length !== 0) {
    return {
      ...item,
      childList: childs,
    };
  }
};
