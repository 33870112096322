import { useAtom } from 'jotai';
import { appsAtom } from '../../../store/appsAtom';
import { useEffect, useState } from 'react';
import { ConfigMenuInfo } from '../../../types';
import { selectedMainCategoryAtom } from '../../../store/selectedMainCategoryAtom';
import { FAVORITEAPP_KEY, RECENTUSEDAPP_KEY } from '../../../constants';
import { favoriteAppsAtom } from '../../../store/favoriteAppsAtom';
import { recentUsedAppsAtom } from '../../../store/recentUsedAppsAtom';
import { MenuTree } from './MenuTree';

export function Sub() {
  const [selectedMainCategory] = useAtom(selectedMainCategoryAtom);
  const [{ data: favoriteApps }] = useAtom(favoriteAppsAtom);
  const [recentApps] = useAtom(recentUsedAppsAtom);
  const [{ data: apps }] = useAtom(appsAtom);
  const [selectedApps, setSelectedApps] = useState<ConfigMenuInfo[]>([]);

  useEffect(() => {
    if (!apps) return;
    if (selectedMainCategory === RECENTUSEDAPP_KEY) {
      setSelectedApps(recentApps);
    } else if (selectedMainCategory === FAVORITEAPP_KEY) {
      setSelectedApps(favoriteApps ?? []);
    } else {
      const selectedApps = apps
        .filter((app) => app.uid === selectedMainCategory)
        .flatMap((app) => app.childList)
        .filter((app) => app !== undefined);

      setSelectedApps(selectedApps);
    }
  }, [apps, selectedMainCategory, favoriteApps, recentApps]);

  return (
    <div className="flex-shrink-0 w-[260px] h-full border-r-[1px] px-[24px]">
      <MenuTree className="mt-[32px]" menuList={selectedApps} />
    </div>
  );
}
