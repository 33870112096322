import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { ConfigMenuInfo } from '../../../../types';
import { useMenuTreeContext } from './Context';
import { selectedAppIdAtom } from '../../../../store/selectedAppIdAtom';
import { addRecentUsedAppAtom } from '../../../../store/recentUsedAppsAtom';
import {
  ChevronDownIcon,
  ChevronUpIcon,
} from '@daouoffice/ui/lib/icons/dop/24';

export interface Props {
  menuItem: ConfigMenuInfo;
}

export function MenuTreeItem({ menuItem }: Props) {
  const { openNodes, setOpenNodes } = useMenuTreeContext();
  const [selectedAppId, setSelectedAppId] = useAtom(selectedAppIdAtom);
  const [, addRecentUsedApp] = useAtom(addRecentUsedAppAtom);
  const isLeaf =
    menuItem.childList?.length === 0 || menuItem.childList === undefined;

  const onClickMenuItemHandler = () => {
    if (isLeaf) {
      onClickLeafItemHandler(menuItem);
    } else if (openNodes.has(menuItem.uid)) {
      const newOpenNodes = new Set(openNodes);
      newOpenNodes.delete(menuItem.uid);
      setOpenNodes(newOpenNodes);
    } else {
      const newOpenNodes = new Set(openNodes);
      newOpenNodes.add(menuItem.uid);
      setOpenNodes(newOpenNodes);
    }
  };

  const onClickLeafItemHandler = (info: ConfigMenuInfo) => {
    setSelectedAppId(info.url);
    addRecentUsedApp({ ...info, childList: undefined });
  };

  return (
    <div className="grid gap-[4px]">
      <button
        className={clsx(
          'relative flex items-center w-full h-[34px] px-[8px] rounded-[8px] cursor-default hover:bg-[#F1F3F4] text-[#3B3B3B] font-[500] text-[14px]',
          {
            'bg-[#E6E7EA]': isLeaf && selectedAppId === menuItem.url,
            'bg-transparent':
              (isLeaf && selectedAppId !== menuItem.uid) || !isLeaf,
          },
        )}
        onClick={() => onClickMenuItemHandler()}
      >
        {menuItem.name}
        {!isLeaf && openNodes.has(menuItem.uid) && (
          <ChevronUpIcon className="absolute size-[16px] right-[8px] text-[#AAAAAA]" />
        )}
        {!isLeaf && !openNodes.has(menuItem.uid) && (
          <ChevronDownIcon className="absolute size-[16px] right-[8px] text-[#AAAAAA]" />
        )}
      </button>
      {openNodes.has(menuItem.uid) &&
        menuItem.childList?.map((info) => (
          <button
            key={info.uid}
            className={clsx(
              'flex items-center h-[34px] mx-[12px] px-[8px] rounded-[8px] cursor-default hover:bg-[#F1F3F4] text-[14px]',
              {
                'bg-[#E6E7EA] text-[#3B3B3B]': selectedAppId === info.uid,
                'bg-transparent text-[#888888]': selectedAppId !== info.uid,
              },
            )}
            onClick={() => onClickLeafItemHandler(info)}
          >
            {info.name}
          </button>
        ))}
    </div>
  );
}
