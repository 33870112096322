'use client';

import type { PropsWithChildren } from 'react';
import * as TranslationsProviderClient from '@daouoffice/next/lib/i18n/client/TranslationsProvider';
import { loadResource } from '@/i18n';

export interface Props {
  locale: string;
}

export const CLINET_NAMESPACES = ['common'];

export function TranslationsProvider({
  children,
  locale = 'ko',
}: PropsWithChildren<Props>) {
  return (
    <TranslationsProviderClient.Provider
      locale={locale}
      namespaces={CLINET_NAMESPACES}
      loadResource={loadResource}
    >
      {children}
    </TranslationsProviderClient.Provider>
  );
}

export default TranslationsProvider;
