import { useSuspenseQuery } from '@tanstack/react-query';
import * as getLinkPlusList from '../api/getLinkPlusList';
import AppList from './AppList';
import NoLinkPlusTemplate from './NoDataTemplate/NoLinkPlusTemplate';
import { useTranslation } from 'react-i18next';
import { useValuesContext } from '../Context';

export function LinkPlusList() {
  const { t } = useTranslation();

  const { tab } = useValuesContext();

  const {
    data: { linkPlusList },
    error,
  } = useSuspenseQuery({
    queryKey: [getLinkPlusList.QUERY_KEY],
    queryFn: async () => await getLinkPlusList.getLinkPlusList(),
  });

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  return (
    <div className="my-4 ml-2">
      {tab === 'All' && (
        <p className="text-[16px] font-medium">{t('organizer.tab.linkplus')}</p>
      )}
      {linkPlusList && linkPlusList.length > 0 ? (
        <AppList
          type="LINK_PLUS"
          appList={linkPlusList}
          scrollable={tab === 'LinkPlus'}
          offset={tab === 'LinkPlus' ? 20 : tab === 'All' ? 5 : 0}
        />
      ) : (
        tab !== 'Works' && <NoLinkPlusTemplate />
      )}
    </div>
  );
}

export default LinkPlusList;
