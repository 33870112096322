import { clsx } from 'clsx';
import { ConfigMenuInfo } from '../../../../types';
import { Provider } from './Context';
import { MenuTreeItem } from './MenuTreeItem';

export interface Props {
  className?: string;
  menuList: ConfigMenuInfo[];
}

export function MenuTree({ className, menuList }: Props) {
  return (
    <Provider>
      <div className={clsx('grid gap-[4px]', className)}>
        {menuList.map((menuItem) => (
          <MenuTreeItem key={menuItem.uid} menuItem={menuItem} />
        ))}
      </div>
    </Provider>
  );
}
