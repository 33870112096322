import { Editor } from '@daouoffice/ui/lib/foundation/Editor';
import { ListBulletIcon } from '@heroicons/react/24/outline';
import {
  BoldIcon,
  ItalicIcon,
  NumberedListIcon,
  StrikeIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { clsx } from 'clsx';

import { IconButton } from '@daouoffice/ui';
import { XMarkCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { Dispatch, SetStateAction } from 'react';

interface Props {
  editor: Editor | null;
  setToolbarShow: Dispatch<SetStateAction<boolean>>;
}
function FormattingToolbar({ editor, setToolbarShow }: Props) {
  if (!editor) return null;
  return (
    <>
      <IconButton title="close" onClick={() => setToolbarShow(false)}>
        <XMarkCircleIcon size={32} />
      </IconButton>
      <IconButton
        title="bold"
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <BoldIcon
          size={24}
          className={clsx({
            'bg-[#979797] text-white': editor.isActive('bold'),
          })}
        />
      </IconButton>
      <IconButton
        title="Italic"
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <ItalicIcon
          size={24}
          className={clsx({
            'bg-[#979797] text-white': editor.isActive('italic'),
          })}
        />
      </IconButton>
      <IconButton
        title="strike"
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <StrikeIcon
          size={24}
          className={clsx('cursor-pointer', {
            'bg-[#979797] text-white': editor.isActive('strike'),
          })}
        />
      </IconButton>
      <IconButton
        title="list-bullet"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
      >
        <ListBulletIcon
          width={24}
          height={24}
          className={clsx('cursor-pointer', {
            'bg-[#979797] text-white': editor.isActive('bulletList'),
          })}
        />
      </IconButton>
      <IconButton
        title="numbered-list"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
      >
        <NumberedListIcon
          size={24}
          className={clsx('cursor-pointer w-8 h-8', {
            'bg-[#979797] text-white': editor.isActive('orderedList'),
          })}
        />
      </IconButton>
    </>
  );
}

export default FormattingToolbar;
