import { SettingsIcon } from '@daouoffice/ui/lib/icons/dop/24';

import { useOrganizerAtom } from '../Provider';
import ToolItem from './ToolItem';
import { FoldIcon } from '@daouoffice/ui/lib/icons/dop/16';

import { useTranslation } from 'react-i18next';

function ToolItemList() {
  const { t } = useTranslation();
  const [, setOrganizerAtom] = useOrganizerAtom();
  const handleOnClickFoldButton = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'flip',
      panelState: 'none',
    }));
  };

  const handleOnClickSettingsButton = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      settingMenuOpenState: !prev.settingMenuOpenState,
    }));
  };

  return (
    <ul className="group_fixed_btn">
      <ToolItem
        key="organizer_settings"
        className=""
        uid={0}
        sortOrder={0}
        name={t('organizer.setting')}
        itemType="svg"
        size="medium"
        onClick={handleOnClickSettingsButton}
      >
        <SettingsIcon />
      </ToolItem>

      <ToolItem
        key="organizer_fold"
        className=""
        uid={0}
        name={t('organizer.fold')}
        itemType="svg"
        sortOrder={0}
        size="medium"
        onClick={handleOnClickFoldButton}
      >
        <FoldIcon />
      </ToolItem>
    </ul>
  );
}
export default ToolItemList;
