import * as Stacker from '@daouoffice/ui/lib/foundation/Stacker';
import { OrganizerMemoSearch } from './Search';
import OrganizerMemoMain from './Main';
import { OrganizerMemoEdit } from './MemoEdit';
import { Provider as MemoProvider } from './Context';
import { Suspense } from 'react';

export type OrganizerMemoPages = 'main' | 'search' | 'memo';
function OrganizerMemo() {
  return (
    <MemoProvider>
      <Stacker.Root defaultPage="main">
        <Stacker.Content name="main" className="w-full h-full">
          <Suspense>
            <OrganizerMemoMain />
          </Suspense>
        </Stacker.Content>

        <Stacker.Content name="search" className="w-full h-full">
          <OrganizerMemoSearch />
        </Stacker.Content>

        <Stacker.Content name="memo" className="w-full h-full">
          <OrganizerMemoEdit />
        </Stacker.Content>
      </Stacker.Root>
    </MemoProvider>
  );
}
export default OrganizerMemo;
