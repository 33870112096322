import { clsx } from 'clsx';
import { ConfigMenuInfoWithCount } from './type';
import { useAtom } from 'jotai';
import { selectedMainCategoryAtom } from '../../../../store/selectedMainCategoryAtom';
import { ChevronRightIcon } from '@daouoffice/ui/lib/icons/dop/24/ChevronRight';

export interface Props {
  menuItem: ConfigMenuInfoWithCount;
}

export function MenuItem({ menuItem }: Props) {
  const [selectedMainCategory, setSelectedMainCategory] = useAtom(
    selectedMainCategoryAtom,
  );

  const iconPropReturned = menuItem.icon
    ? typeof menuItem.icon === 'function'
      ? menuItem.icon()
      : menuItem.icon
    : undefined;

  const iconPart =
    iconPropReturned &&
    (typeof iconPropReturned === 'string' ? (
      <span
        className="size-[20px]"
        dangerouslySetInnerHTML={{ __html: iconPropReturned }}
      />
    ) : (
      <span className="size-[20px]">{iconPropReturned}</span>
    ));

  return (
    <button
      className={clsx(
        'relative flex items-center w-full h-[34px] px-[8px] rounded-[8px] cursor-default hover:bg-[#DBE0EB] text-[#3B3B3B]',
        { 'bg-transparent': menuItem.uid !== selectedMainCategory },
        { 'bg-[#DBE0EB]': menuItem.uid === selectedMainCategory },
      )}
      onClick={() => setSelectedMainCategory(menuItem.uid)}
    >
      {iconPart}
      <span className="ml-[8px] text-[#3B3B3B] font-[500] text-[14px]">
        {menuItem.name}
      </span>
      {menuItem.count !== undefined && (
        <span className="ml-[4px] h-[16px] px-[6px] bg-[#00A1B9] rounded-[6px] text-white text-[10px] font-[400]">
          {menuItem.count}
        </span>
      )}
      <ChevronRightIcon className="absolute right-[6px] text-[#999999] size-[16px]" />
    </button>
  );
}
