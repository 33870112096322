import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { checkExistSimpleCode } from './api/checkExistSimpleCode';
import { SimpleCodeFormPrimitives } from './index';
import { SimpleCodeChanger } from './SimpleCodeChanger';
import { SimpleCodeChecker } from './SimpleCodeChecker';
import { SimpleCodePasswordChecker } from './SimpleCodePasswordChecker';
import { SimpleCodeRegister } from './SimpleCodeRegister';
import { clientType, formType } from './types';

export interface Props {
  /** @property form 타입 */
  appCode?: string;
  /** @property form 타입 */
  formType?: formType;
  /** @property submit 버튼 텍스트 */
  subitButtonText?: string;
  /** @property cancel 버튼 텍스트 */
  cancelButtonText?: string;
  /** @property title 텍스트 */
  title?: string;
  /** @property infoMessage 텍스트 */
  infoMessage?: string;
  /** @property className */
  className?: string;
  /** @property type */
  type?: clientType;
  /** @property onSuccess 함수 */
  onSuccess?: () => void;
}

export function SimpleCodeFormClient({
  formType: defaultFormType,
  appCode,
  type = 'web',
  ...restProps
}: Props) {
  const [formType, setFormType] = useState<formType>(
    defaultFormType ?? 'checker',
  );
  const router = useRouter();

  useEffect(() => {
    if (formType === 'checker' || !defaultFormType) {
      checkExistSimpleCode()
        .then((resp) => {
          setFormType(resp?.data?.isExistSimpleCode ? 'checker' : 'register');
        })
        .catch((e) => console.error(e));
    }

    if (formType === 'changer') {
      checkExistSimpleCode()
        .then((resp) => {
          setFormType(resp?.data?.isExistSimpleCode ? 'changer' : 'register');
        })
        .catch((e) => console.error(e));
    }
  }, [formType]);

  const handleSimpleCodeCheckerClick = () => {
    setFormType('checker');
  };

  const onSuccessHandler = () => {
    if (formType === 'checker') {
      router.push('/');
    } else if (formType === 'register') {
      setFormType('checker');
    } else if (formType === 'password') {
      router.push('/');
    } else if (formType === 'changer') {
      router.back();
    }
  };

  const onCancelHandler = () => {
    if (formType === 'changer') {
      router.back();
    }
  };

  const SimpleCodeForm =
    formType === 'checker'
      ? SimpleCodeChecker
      : formType === 'register'
        ? SimpleCodeRegister
        : formType === 'password'
          ? SimpleCodePasswordChecker
          : SimpleCodeChanger;

  return (
    <SimpleCodeFormPrimitives.Root appCode={appCode}>
      <SimpleCodeForm
        onClick={handleSimpleCodeCheckerClick}
        onSuccess={onSuccessHandler}
        onCancel={onCancelHandler}
        type={type}
        {...restProps}
      />
    </SimpleCodeFormPrimitives.Root>
  );
}

export default SimpleCodeFormClient;
