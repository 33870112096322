import { clsx } from 'clsx';
import { MenuItem } from './MenuItem';
import { ConfigMenuInfoWithCount } from './type';

export interface Props {
  className?: string;
  menuList: ConfigMenuInfoWithCount[];
}

export function MenuList({ className, menuList }: Props) {
  return (
    <div className={clsx(className, 'grid gap-[8px]')}>
      {menuList.map((menuItem) => {
        return <MenuItem key={menuItem.uid} menuItem={menuItem} />;
      })}
    </div>
  );
}
