import { useScreenLockContext } from '@daouoffice/next/lib/screen-lock/Provider';
import * as Popover from '@daouoffice/ui/lib/foundation/Popover';
import { EllipsisHorizontalIcon } from '@daouoffice/ui/lib/icons/dop/24/EllipsisHorizontal';
import type { IUserAppInfo } from '@daouoffice/ui/lib/service/AppsDock';
import { useRouter } from 'next/navigation';
import { FuncButton } from '../../FuncButton';

export interface Props {
  data: IUserAppInfo[];
}

export function AppMoreMenuLayer({ data }: Props) {
  const { setAppInfo } = useScreenLockContext();
  const router = useRouter();

  const onClickHandler = (item: IUserAppInfo) => {
    setAppInfo({
      appId: `${item.uid}`,
      appUrl: item.url,
      appName: item.name,
    });
    router.push(item.url);
  };

  return (
    <div className="group_more menu_open open">
      <Popover.Root>
        <Popover.Trigger>
          <FuncButton icon={EllipsisHorizontalIcon} />
        </Popover.Trigger>
        <Popover.Content side="right" sideOffset={10} className="select_list">
          <ul>
            {data.map((item) => (
              <li key={item.uid}>
                <button
                  onClick={() => onClickHandler(item)}
                  className="py-[3px] px-[12px] gap-[13px]"
                >
                  <i
                    className="icon ic_medium"
                    dangerouslySetInnerHTML={{ __html: item.icon as string }}
                  />
                  <span className="txt">{item.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </Popover.Content>
      </Popover.Root>
    </div>
  );
}

export default AppMoreMenuLayer;
