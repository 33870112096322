import { useSuspenseQuery } from '@tanstack/react-query';
import * as getWorksList from '../api/getWorksList';
import AppList from './AppList';
import NoWorksTemplate from './NoDataTemplate/NoWorkTemplate';
import { useValuesContext } from '../Context';

export function WorksList() {
  const { tab } = useValuesContext();

  const {
    data: { worksList },
    error,
  } = useSuspenseQuery({
    queryKey: [getWorksList.QUERY_KEY],
    queryFn: async () => await getWorksList.getWorksList(),
  });

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  return (
    <div className="my-6 ml-2">
      {tab === 'All' && <p className="text-[16px] font-medium">Works</p>}
      {worksList && worksList.length > 0 ? (
        <AppList
          type="WORKS"
          appList={worksList}
          scrollable={tab === 'Works'}
          offset={tab === 'Works' ? 20 : tab === 'All' ? 5 : 0}
        />
      ) : (
        tab !== 'LinkPlus' && <NoWorksTemplate />
      )}
    </div>
  );
}

export default WorksList;
