import { EllipsisVerticalIcon } from '@daouoffice/ui/lib/icons/dop/24/EllipsisVertical';
import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { ShortcutItemInfo } from '../../types';
import { useOrganizerValuesAtom } from '../../Provider';
import { ToolItem } from '../../ToolItemList';
import AdditionalItemList from './AdditionalItemList';
import * as Popover from '@daouoffice/ui/lib/foundation/Popover';
import { useTranslation } from 'react-i18next';

interface Props {
  additionalItemList?: ShortcutItemInfo[];
}

function AdditionalItem({ additionalItemList }: Props) {
  const { t } = useTranslation();
  const [isMoreMenuOpened, setMoreMenuState] = useState(false);
  const { organizerState, panelState, settingMenuOpenState } =
    useOrganizerValuesAtom();

  const handleOnClickMoreButton = () => {
    setMoreMenuState(!isMoreMenuOpened);
  };

  useEffect(() => {
    setMoreMenuState(false);
  }, [organizerState, panelState, settingMenuOpenState]);

  if (additionalItemList && additionalItemList.length === 0) {
    return <></>;
  }

  return (
    <>
      <Popover.Root modal={false}>
        <Popover.Trigger>
          <ToolItem
            uid={-1}
            itemType="svg"
            name={t('organizer.seemore')}
            size="medium"
            onClick={handleOnClickMoreButton}
          >
            <EllipsisVerticalIcon />
          </ToolItem>
        </Popover.Trigger>
        <Popover.Content side="left">
          <div
            className={clsx('group_more', isMoreMenuOpened ? 'menu_open' : '')}
          >
            <div className="select_list left">
              <AdditionalItemList additionalItemList={additionalItemList} />
            </div>
          </div>
        </Popover.Content>
      </Popover.Root>
    </>
  );
}

export default AdditionalItem;
