import { useInView } from 'react-intersection-observer';
import { AppType } from '../../type';
import { useInfiniteScrollQuery } from './useInfiniteScrollQuery';
import { useEffect } from 'react';
import AppItem from '../AppItem';

interface Props {
  type: string;
  appList: AppType[];
  offset: number;
}

export function ScrollList({ type, appList, offset }: Props) {
  const {
    scrollList,
    getNextPage,
    isScrollSuccess,
    isHasNextPage,
    isError,
    isLoading,
    isFetching,
  } = useInfiniteScrollQuery({ type, appList, offset });

  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView && isHasNextPage) {
      void getNextPage();
    }
  }, [inView]);

  if (isLoading) {
    return <div>Loading ... </div>;
  }

  if (isError) {
    return <></>;
  }

  return (
    <>
      {isScrollSuccess &&
        scrollList &&
        scrollList.map((page_data) => {
          return page_data.map((appItem) => (
            <AppItem key={appItem.id} type={type} appItem={appItem} />
          ));
        })}
      {isHasNextPage && !isFetching && <div ref={ref} />}
    </>
  );
}

export default ScrollList;
