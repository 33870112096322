import { IconButton } from '@daouoffice/ui';
import MemoList from '../MemoList';
import { PlusIcon } from '@daouoffice/ui/lib/icons/heroicons/24/solid';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { OrganizerMemoPages } from '..';
import { useSuspenseQuery } from '@tanstack/react-query';
import * as getMemoListQuery from '../apis/getMemoList';
import NoMemoTemplate from './NoMemoTemplate';
import { useEffect } from 'react';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function MemoMainContent() {
  const { push } = useStackMethod<OrganizerMemoPages>();

  const { t } = useTranslation();

  const { memoContext, setMemoContext } = useMemoContext();

  const {
    data: { data: memoListInfo },
    error,
  } = useSuspenseQuery({
    queryKey: [getMemoListQuery.QUERY_KEY],
    queryFn: async () => await getMemoListQuery.getMemoList(),
  });

  useEffect(() => {
    if (memoListInfo) {
      setMemoContext({
        ...memoContext,
        favoriteCount: memoListInfo.memoList.filter((memo) => memo.isFavorite)
          .length,
      });
    }
  }, [memoListInfo]);

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  return (
    <div className="relative">
      <div className="text-gray-400 my-2">
        <span>{t('organizer.memo.title.all')}</span>
        <span className="text-black font-medium">{` ${memoListInfo.count}`}</span>
      </div>
      {memoListInfo.memoList.length > 0 ? (
        <MemoList memoList={memoListInfo.memoList} />
      ) : (
        <NoMemoTemplate />
      )}
      <IconButton
        title=""
        size="xl"
        className="fixed bottom-4 right-5"
        onClick={() => {
          push('memo');
        }}
      >
        <PlusIcon
          width={44}
          height={44}
          color="#FFFFFF"
          className="p-2 rounded-xl bg-black"
        ></PlusIcon>
      </IconButton>
    </div>
  );
}

export default MemoMainContent;
