import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast, Templates } from '../../../../Toast';
import { postLinkShortcut } from '../api/createShortcutQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY as getOrganizerQueryId } from '../../../apis/getOrganizerQuery';

export function OrganizerLinkAdder() {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { info } = useToast();

  const [linkUrl, setLinkUrl] = useState('');

  const handleOnSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [getOrganizerQueryId],
    });
    setLinkUrl('');
    info(<Templates.Basic description={t('organizer.toast.add')} />);
  };

  const postLinkShortcutMutaion = useMutation({
    mutationFn: (url: string) => postLinkShortcut(url),
    onSuccess: () => {
      handleOnSuccess();
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      handleClickAdd(linkUrl);
    }
  };

  const handleClickAdd = (url: string) => {
    if (!url) {
      info(<Templates.Basic description={t('organizer.toast.url.empty')} />);
    } else {
      postLinkShortcutMutaion.mutate(url);
    }
  };

  return (
    <div className="mt-3 ml-2">
      <p className="text-[16px] font-medium">{t('organizer.shortcut.link')}</p>
      <div className="my-3 min-w-[300px] max-w-[300px]">
        <div className="flex items-center">
          <label className="label blind" htmlFor="test_input">
            {t('organizer.input.link')}
          </label>
          <input
            className="w-[230px] h-[24px] p-4 mr-3 text-[14px] border-solid border border-gray-400 rounded-md"
            type="text"
            title={t('organizer.input.link')}
            placeholder="http://"
            value={linkUrl}
            onKeyDown={handleOnKeyDown}
            onChange={(e) => setLinkUrl(e.target.value)}
          />
          <button
            className="w-[50px] h-[100%] py-1 px-2 text-center rounded-md bg-black text-white"
            title={t('organizer.add.button')}
            onClick={() => {
              handleClickAdd(linkUrl);
            }}
          >
            {t('organizer.add.button')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default OrganizerLinkAdder;
