'use client';

import { Provider, ProviderProps } from '@daouoffice/next/components/Toast';
import type { PropsWithChildren } from 'react';

export function ToastProvider({
  children,
  direction,
  duration,
  maxLength,
  usePortal,
  ...props
}: PropsWithChildren<ProviderProps>) {
  return (
    <Provider
      direction={direction}
      duration={duration}
      maxLength={maxLength}
      usePortal={usePortal}
      {...props}
    >
      {children}
    </Provider>
  );
}
