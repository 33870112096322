import { useEffect, useRef, useState } from 'react';
import { ShortcutItemInfo } from '../types';
import { useWindowResizeObserver } from '@daouoffice/ui/lib/hooks/useWindowResizeObserver';
import ShortcutListItem from './ShortcutItem';
import AdditionalItem from './AdditionalItem';
import { clsx } from 'clsx';
import { ORGANIZER_ITEM_HEIGHT, SETTING_AREA_HEIGHT } from '../constants';

export interface Props {
  /** @property Shortcut 리스트 */
  shortcutItemList?: ShortcutItemInfo[];
  /** @property 클래스네임 */
  className: string;
}

/**
 * Organizer.ActionItemList 컴포넌트
 * @returns
 */

export function ShortcutItemList({ shortcutItemList, className }: Props) {
  const ref = useRef<HTMLUListElement>(null);

  const [renderLength, setRenderLength] = useState(5);
  useEffect(() => {
    calculateUlHeight();
  }, [shortcutItemList]);

  const calculateUlHeight = () => {
    if (ref.current && shortcutItemList) {
      const { height } = ref.current.getBoundingClientRect();
      const isItemOverflow =
        ORGANIZER_ITEM_HEIGHT * shortcutItemList.length >
        height - SETTING_AREA_HEIGHT;
      if (isItemOverflow) {
        const changedRenderLength = height / ORGANIZER_ITEM_HEIGHT - 2;
        setRenderLength(changedRenderLength >= 0 ? changedRenderLength : 0);
      } else {
        if (renderLength !== shortcutItemList.length) {
          setRenderLength(shortcutItemList.length);
        }
      }
    }
  };
  useWindowResizeObserver(calculateUlHeight, {
    debounced: true,
    debouncedTimeMs: 100,
  });

  const [itemList, additionalItemList] = [
    shortcutItemList?.slice(0, renderLength),
    shortcutItemList?.slice(renderLength),
  ];

  return (
    <>
      <ul
        className={clsx(className, 'flex-1')}
        ref={ref}
        style={{ height: renderLength * ORGANIZER_ITEM_HEIGHT }}
      >
        {itemList?.map((item) => (
          <ShortcutListItem
            key={item.uid + item.itemType}
            className="link_list"
            uid={item.uid}
            sortOrder={item.sortOrder}
            name={item.name}
            itemId={item.itemId}
            appType={item.appType}
            itemType={item.itemType}
            size={item.size}
            icon={item.icon}
            url={item.url}
          />
        ))}
        <AdditionalItem additionalItemList={additionalItemList} />
      </ul>
    </>
  );
}

export default ShortcutItemList;
