import { useEffect } from 'react';
import { useTranslation } from '../../../lib/i18n/client/useTranslation';
import { editSimpleCode } from '../api/editSimpleCode';
import { ResponseBody } from '../api/types';
import { SimpleCodeFormPrimitives } from '../index';
import { useActionsContext, useValuesContext } from '../primitives/Context';
import { getOnlyNumber, isContinuousNumbers, isSameNumbers } from '../utils';

export interface Props {
  /** @property 기존 비밀번호 필요 여부 */
  isCurCodeRequired?: boolean;
  /** @property submit 버튼 텍스트 */
  submitButtonText?: string;
  /** @property cancel 버튼 텍스트 */
  cancelButtonText?: string;
  /** @property title 텍스트 */
  title?: string;
  /** @property infoMessage 텍스트 */
  infoMessage?: string;
  /** @property className */
  className?: string;
  /** @property 앱 코드 */
  appCode?: string;
  /** @property onSuccess 함수 */
  onSuccess?: () => void;
  /** @property onCancel 함수 */
  onCancel?: () => void;
}

export function SimpleCodeChanger({
  isCurCodeRequired = true,
  infoMessage,
  className,
  onSuccess,
  onCancel,
}: Props) {
  const { t } = useTranslation();
  const {
    appCode,
    simpleCode,
    newSimpleCode,
    newSimpleCodeCheck,
    errorMessage,
  } = useValuesContext();
  const {
    setSimpleCode,
    setNewSimpleCode,
    setNewSimpleCodeCheck,
    setErrorMessage,
  } = useActionsContext();

  // TODO: API 연동.
  const enterKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && changeHandler();

  const changeHandler = () => {
    checkIsValidNewSimpleCode();
  };

  const cancelHandler = () => {
    alert('canceled!');
    onCancel && onCancel();
  };

  const setErrorMessageByTextChanged = (text: string) => {
    text.length > 4
      ? setErrorMessage(t('simplecode.error.max.length'))
      : isNaN(Number(text))
        ? setErrorMessage(t('simplecode.error.not.number'))
        : setErrorMessage('');
  };

  const isValidSimpleCode = (newCode: string, newCheckCode: string) =>
    newCode === newCheckCode &&
    newCode.length === 4 &&
    newCheckCode.length === 4;

  const checkIsValidNewSimpleCode = () => {
    const isValid = isCurCodeRequired
      ? isValidSimpleCode(newSimpleCode, newSimpleCodeCheck) &&
        simpleCode.length === 4
      : isValidSimpleCode(newSimpleCode, newSimpleCodeCheck);

    if (isValid) {
      handleSimpleCode();
    } else {
      setErrorMessage(t('simplecode.error.invalid.number'));
    }
  };

  const handleSimpleCode = () => {
    if (isSameNumbers(newSimpleCode)) {
      setErrorMessage(
        `'${newSimpleCode}' ` + t('simplecode.error.same.number'),
      );
    } else if (isContinuousNumbers(newSimpleCode)) {
      setErrorMessage(
        `'${newSimpleCode}' ` + t('simplecode.error.continuous.number'),
      );
    } else {
      changeSimpleCode();
    }
  };

  const changeSimpleCode = () => {
    void editSimpleCode(simpleCode, newSimpleCode, newSimpleCodeCheck)
      .then((res: ResponseBody<string>) => {
        setErrorMessage(res?.message ?? '');
        res?.data && onSuccessHandler();
      })
      .catch((e: Error) => {
        setErrorMessage(e.message);
      });
  };

  const onSuccessHandler = () => {
    onSuccess && onSuccess();
  };

  useEffect(() => {
    setSimpleCode('');
    setErrorMessage('');
    setNewSimpleCode('');
  }, [appCode, setErrorMessage, setSimpleCode, setNewSimpleCode]);

  return (
    <SimpleCodeFormPrimitives.Contents className={className}>
      <SimpleCodeFormPrimitives.Title
        title={t('simplecode.change.title')}
        className="mb-4 text-3xl"
      />
      <SimpleCodeFormPrimitives.InfoMessage
        message={infoMessage}
        className="mb-4"
      />
      {isCurCodeRequired && (
        <SimpleCodeFormPrimitives.TextField
          title={t('simplecode.origin.simple.code')}
          value={simpleCode}
          placeholder={t('simplecode.placeholder.format')}
          className="mb-4"
          onTextChange={(text) => {
            setErrorMessageByTextChanged(text);
            text.length <= 4 && setSimpleCode(getOnlyNumber(text));
          }}
          onKeyPress={enterKeyHandler}
        />
      )}
      <SimpleCodeFormPrimitives.TextField
        title={t('simplecode.new.code')}
        value={newSimpleCode}
        placeholder={t('simplecode.placeholder.format')}
        className="mb-4"
        onTextChange={(text) => {
          setErrorMessageByTextChanged(text);
          text.length <= 4 && setNewSimpleCode(getOnlyNumber(text));
        }}
        onKeyPress={enterKeyHandler}
      />
      <SimpleCodeFormPrimitives.TextField
        title={t('simplecode.new.code.check')}
        value={newSimpleCodeCheck}
        placeholder={t('simplecode.placeholder.format')}
        className="mb-4"
        onTextChange={(text) => {
          setErrorMessageByTextChanged(text);
          text.length <= 4 && setNewSimpleCodeCheck(getOnlyNumber(text));
        }}
        onKeyPress={enterKeyHandler}
      />
      <SimpleCodeFormPrimitives.ErrorMessage
        message={errorMessage ?? ''}
        className="mb-4"
      />
      <div className="flex">
        <SimpleCodeFormPrimitives.ActionButton
          title={t('simplecode.cancel.button.title')}
          className="mr-2 flex-auto"
          type="negative"
          handleOnAction={cancelHandler}
        />
        <SimpleCodeFormPrimitives.ActionButton
          title={t('simplecode.save.button.title')}
          className="flex-auto"
          handleOnAction={changeHandler}
        />
      </div>
    </SimpleCodeFormPrimitives.Contents>
  );
}

export default SimpleCodeChanger;
