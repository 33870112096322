import { AdditionIcon, MailIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { CalendarIcon } from '@daouoffice/ui/lib/icons/dop/24/Calendar';
import { DocumentIcon } from '@daouoffice/ui/lib/icons/dop/24/Document';
import { PanelState } from '../types';

export function getDefaultAppIcon(appName: PanelState) {
  switch (appName) {
    case 'calendar':
      return (
        <CalendarIcon
          className="bg-[#3ED1DC] text-white rounded-full"
          size={28}
        />
      );
    case 'mail':
      return <MailIcon className="bg-[#3ED1DC] text-white rounded-full" />;
    case 'memo':
      return (
        <DocumentIcon
          className="bg-[#3ED1DC] text-white rounded-full"
          size={28}
        />
      );
    case 'shortcut':
      return <AdditionIcon className="rounded-full border" />;
    default:
      return <></>;
  }
}
