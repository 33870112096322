import { Button } from '@daouoffice/ui';
import { UnfoldIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { useOrganizerAtom } from '../Provider';
import { useTranslation } from 'react-i18next';

export function UnfoldButton() {
  const [organizerAtom, setOrganizerAtom] = useOrganizerAtom();
  const { t } = useTranslation();
  const handleOnClick = () => {
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: 'default',
    }));
  };

  return (
    <>
      {organizerAtom.organizerState === 'flip' ? (
        <Button title="" className="btn_flip" onClick={handleOnClick}>
          <i className="icon ic_small">
            <UnfoldIcon size={18} />
          </i>
          <span className="blind">{t('organizer.expand')}</span>
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}
