'use client';

import { useAtom } from 'jotai/react';
import { usePathname } from 'next/navigation';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { checkScreenLockTarget } from '../../components/SimpleCodeForm/api/checkScreenLockTarget';
import { AccessInfo, accessInfoAtom, appInfoAtom } from './store';
import { AppInfo } from './types';
import { clearTimer, initTimer } from './utils';

export interface ScreenLockContextValues {
  isLocked: boolean;
  appInfo?: AppInfo;
}

export interface ScreenLockContextActions {
  setLockState: React.Dispatch<React.SetStateAction<boolean>>;
  setAppInfo: React.Dispatch<React.SetStateAction<AppInfo>>;
  regist: (
    appId?: string,
    appUrl?: string,
    appName?: string,
    isFirstVisited?: boolean,
  ) => void;
}

export const ValuesContext = createContext<ScreenLockContextValues>({
  isLocked: false,
  appInfo: {},
});

export const ActionsContext = createContext<ScreenLockContextActions>({
  setLockState: () => undefined,
  setAppInfo: () => undefined,
  regist: () => undefined,
});

export function Provider({ children }: PropsWithChildren) {
  const pathName = usePathname();
  const [accessInfo, setAccessInfo] = useAtom(accessInfoAtom);
  const [appInfo, setAppInfo] = useAtom(appInfoAtom);
  const [isLocked, setLockState] = useState<boolean>(
    () => !accessInfo.some((info) => info.appId === appInfo.appId),
  );
  const EXCLUSION_PATH_NAMES = ['/', '/eacc-app', '/dashboard', '/manage/home'];

  const checkExclusivePath = (url: string) => {
    return EXCLUSION_PATH_NAMES.some((_url) =>
      url === '/' ? url === _url : _url.includes(url),
    );
  };
  const deleteAccessInfo = (appId: string | undefined) =>
    appId &&
    setAccessInfo((prev) => prev.filter((info) => info.appId !== appId));

  const addAccessInfo = (addedAccessInfo: AccessInfo) => {
    setAccessInfo((prev) => [
      ...prev.filter((info) => info.appId !== addedAccessInfo.appId),
      addedAccessInfo,
    ]);
  };

  // TODO : FCM message Data 정보 가져와, FCM 메시지 데이터에 정보가 있을 경우, accessInfo에 추가 후 true 값 리턴.
  const checkAppVisited = (appId: string | undefined) =>
    appId && accessInfo.some((info) => info.appId === appId);

  // @function regist: 앱 정보를 등록하고, 화면 잠금 여부를 확인합니다.
  const regist = (appId?: string, appUrl?: string, appName?: string) => {
    // 잠금 화면 제외 대상 URL이거나 현재 URL과 AppUrl이 다를 경우, 잠금 해제
    // DOP-2152 통합테스트 간편 비밀번호 Fail 건 : 잠겨 있는 앱에 대해 / 추가로 url 접근시 잠금 풀리는 현상 대응.
    if (
      checkExclusivePath(pathName) ||
      (appUrl && pathName !== appUrl && pathName !== appUrl + '/')
    ) {
      setLockState(false);
      return;
    }

    //통합 설정, 경영업무포탈 첫 진입 시, 방문 기록이 없는 경우 -> 방문 기록 추가
    if (
      appId === undefined &&
      accessInfo.some((info) => info.appName === appName)
    ) {
      addAccessInfo({ appName: appName ?? '', accessTime: new Date() });
      // 기존에 방문했던 기록이 있는 경우 -> 화면 잠금 해제 후, 타이머 초기화
    } else if (checkAppVisited(appId)) {
      setLockState(false);
      initTimer({ appId, appUrl, appName }, accessInfo, () =>
        deleteAccessInfo(appId),
      );
      // 방문기록이 없는 경우, 앱 정보 등록 후, 화면 잠금 여부 확인
    } else {
      // TODO: 매번 해당 API 호출 개선, jotai asyncStorage 사용해 수정.
      appId &&
        checkScreenLockTarget(appId)
          .then(() => {
            clearTimer();
            setLockState(true);
          })
          .catch(() => {
            setLockState(false);
            addAccessInfo({
              appId: appInfo.appId,
              appUrl: appInfo.appUrl,
              accessTime: new Date(),
            });
          });
    }
  };

  const actionsContext = {
    setLockState,
    setAppInfo,
    regist,
  };

  const valuesContext = {
    isLocked,
    appInfo,
  };

  useEffect(() => {
    regist(appInfo.appId, appInfo.appUrl, appInfo.appName);
  }, [appInfo]);

  useEffect(() => {
    if (!isLocked && appInfo.appId) {
      addAccessInfo({
        appId: appInfo.appId,
        appUrl: appInfo.appUrl,
        accessTime: new Date(),
      });
    }
  }, [isLocked]);

  useEffect(() => {
    accessInfo &&
      appInfo &&
      setLockState(
        () => !accessInfo.some((info) => info.appId === appInfo.appId),
      );
    clearTimer();
    initTimer(
      {
        appId: appInfo.appId,
        appUrl: appInfo.appUrl,
        appName: appInfo.appName,
      },
      accessInfo,
      () => regist(appInfo.appId, appInfo.appUrl, appInfo.appName),
    );
  }, [accessInfo, appInfo]);

  return (
    <ActionsContext.Provider value={actionsContext}>
      <ValuesContext.Provider value={valuesContext}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => useContext(ValuesContext);
export const useActionsContext = () => useContext(ActionsContext);

export const useScreenLockContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};

export default Provider;
