'use client';
import React, { useState } from 'react';

export type TContextValue = Record<string, string>;

export interface IContextValues {
  readonly tab: string;
}

export interface IContextActions {
  setTab: React.Dispatch<React.SetStateAction<string>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  tab: '',
});

export const ActionsContext = React.createContext<IContextActions>({
  setTab: () => undefined,
});

export const Context = React.createContext<TContextValue>({});

export function Provider({ children }: React.PropsWithChildren) {
  const [tab, setTab] = useState('All');

  const ctxValues = {
    tab,
  };

  const ctxActions = {
    setTab,
  };

  return (
    <ActionsContext.Provider value={ctxActions}>
      <ValuesContext.Provider value={ctxValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useValuesContext = () => React.useContext(ValuesContext);
export const useActionsContext = () => React.useContext(ActionsContext);

export const useContext = () => {
  const values = useValuesContext();
  const actions = useActionsContext();

  return {
    ...values,
    ...actions,
  };
};
