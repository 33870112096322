import { useAtom } from 'jotai';
import { PropsWithChildren, useEffect } from 'react';
import { RECENTUSEDAPP_KEY } from '../../constants';
import {
  selectedAppPathAtom,
  setSelectedAppIdAtom,
  setSelectedAppIdByPathAtom,
} from '../../store/selectedAppIdAtom';
import { selectedMainCategoryAtom } from '../../store/selectedMainCategoryAtom';

export interface Props {
  contextPath?: string;
  configPath?: string;
  onPageChange?: (pageURL: string) => void;
}

export function Container({
  contextPath,
  configPath,
  onPageChange,
  children,
}: PropsWithChildren<Props>) {
  const [, setSelectedAppIdByPath] = useAtom(setSelectedAppIdByPathAtom);
  const [, setSelectedAppId] = useAtom(setSelectedAppIdAtom);
  const [selectedAppPath] = useAtom(selectedAppPathAtom);
  const [, setSelectedMainCategory] = useAtom(selectedMainCategoryAtom);

  useEffect(() => {
    return () => {
      setSelectedAppId('');
      setSelectedMainCategory(RECENTUSEDAPP_KEY);
    };
  }, [setSelectedAppId, setSelectedMainCategory]);

  useEffect(() => {
    if (contextPath && configPath)
      setSelectedAppIdByPath({ path: configPath, contextPath });
  }, [contextPath, configPath, setSelectedAppIdByPath]);

  useEffect(() => {
    onPageChange && onPageChange(selectedAppPath);
  }, [onPageChange, selectedAppPath]);

  return <div className="relative flex size-full">{children}</div>;
}
