import { Button } from '@daouoffice/ui';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
interface Props {
  onClickCancel: () => void;
  onClickConfirm: () => void;
}
function DeleteMemoForm({ onClickCancel, onClickConfirm }: Props) {
  const { t } = useTranslation();
  return (
    <div className="p-5 bg-white min-w-[310px] rounded-md shadow-[0_8px_16px_rgb(0_0_0_/16%)]">
      <h2 className="mb-3">{t('organizer.memo.delete')}</h2>
      <p className="mb-2">{t('organizer.memo.alert.delete')}</p>

      <div className="flex justify-end">
        <Button
          shape="rect"
          size="medium"
          styleType="line"
          onClick={onClickCancel}
          colorset="major"
          title={t('organizer.memo.cancel')}
        />
        <Button
          shape="rect"
          size="medium"
          className="ml-2"
          onClick={onClickConfirm}
          title={t('organizer.memo.confirm')}
        />
      </div>
    </div>
  );
}

export default DeleteMemoForm;
