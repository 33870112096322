import { clsx } from 'clsx';
import Panel from '@daouoffice/ui/lib/foundation/Panel';

import { useContext } from './Context';
import { SettingsMenu } from './SettingsMenu';
import { UnfoldButton } from './UnfoldButton';
import OrganizerDefaultApp from './DefaultApp';
import { useOrganizerValuesAtom } from './Provider';
import DefaultMenuList from './DefaultMenuList';
import { ShortcutItemList } from './ShortcutList';
import ToolItemList from './ToolItemList/ToolItemList';
import { ORGANIZER_MIN_HEIGHT } from './constants';

export function Body() {
  const ctx = useContext();
  const organizerAtom = useOrganizerValuesAtom();

  return (
    <>
      <div
        className={clsx('organizer', {
          flip: organizerAtom.organizerState === 'flip',
        })}
        style={{ minHeight: ORGANIZER_MIN_HEIGHT }}
      >
        <div
          className="wrap_organizer"
          style={{ minHeight: ORGANIZER_MIN_HEIGHT }}
        >
          <DefaultMenuList />
          <p className="divider h_line" />
          <ShortcutItemList
            shortcutItemList={ctx.shortcutItems}
            className="group_link_list flex-1 overflow-y-hidden"
          />

          <ToolItemList />
        </div>
        <UnfoldButton />

        <Panel
          usePortal
          open={organizerAtom.panelState !== 'none'}
          direction="right"
        >
          <div className="bg-gray-100 w-full h-full">
            <OrganizerDefaultApp />
          </div>
        </Panel>

        <Panel
          usePortal={true}
          className="z-10"
          open={organizerAtom.settingMenuOpenState}
          direction="right"
        >
          <div className="bg-gray-100 w-full h-full">
            <SettingsMenu />
          </div>
        </Panel>
      </div>
    </>
  );
}
