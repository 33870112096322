import { clsx } from 'clsx';
import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { DeleteIcon, HamburgerMenuIcon } from '@daouoffice/ui/lib/icons/dop/24';
import LinkItem from '../../LinkItem';
import { ShortcutItemInfo, ShortcutItemType } from '../../types';
import { useTranslation } from 'react-i18next';

export interface Props {
  index: number;
  item: ShortcutItemInfo;
  itemType: ShortcutItemType;
  onDragStart: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDragEnter: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDragEnd: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDragOver: (e: React.DragEvent<HTMLLIElement>, index: number) => void;
  onDelete: (uid: number, type: ShortcutItemType) => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number,
    type: ShortcutItemType,
  ) => void;
}

export function SettingsMenuItem({
  index,
  item,
  itemType,
  onDragStart,
  onDragEnter,
  onDragEnd,
  onDragOver,
  onDelete,
  onChange,
}: Props) {
  const { t } = useTranslation();
  const isAppItem = itemType === 'APP';

  return (
    <li
      className="dop_drag_list"
      id="item_o_2"
      draggable
      onDragStart={(e) => onDragStart(e, index)}
      onDragEnter={(e) => onDragEnter(e, index)}
      onDragOver={(e) => onDragOver(e, index)}
      onDragEnd={(e) => onDragEnd(e, index)}
    >
      <div className="menu_name">
        <div className="btn_icon medium handler">
          <HamburgerMenuIcon className="ic_medium" />
        </div>
        <LinkItem
          id={item.uid}
          name={item.name}
          size={24}
          faviconUrl={item.icon}
          className="icon ic_medium"
        />
        <div className="group_input active">
          <div className="input_basic">
            <input
              className={clsx('input_txt', { '!bg-gray-300': isAppItem })}
              id="test_id"
              type="text"
              title=""
              value={item.name}
              disabled={isAppItem}
              onChange={(e) => onChange(e, item.uid, item.itemType)}
            />
          </div>
        </div>
      </div>
      <Button
        title=""
        className="btn_icon medium"
        onClick={() => onDelete(item.uid, item.itemType)}
      >
        <DeleteIcon className="ic_medium" />
        <span className="blind">
          {t('organizer.shortcut.setting.button.close')}
        </span>
      </Button>
    </li>
  );
}

export default SettingsMenuItem;
