'use client';

import OrgChart from '@daouoffice/next/components/OrgChart';
import { useTranslation } from '@daouoffice/next/lib/i18n/client/useTranslation';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { OrgChartIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { FuncButton } from '../FuncButton';

export function OrgChartButton() {
  const { t } = useTranslation();

  return (
    <Dialog.Root modal={false}>
      <Dialog.Trigger>
        <FuncButton icon={OrgChartIcon} label={t('조직도')} />
      </Dialog.Trigger>
      <Dialog.Content size="resizeable">
        <OrgChart />
      </Dialog.Content>
    </Dialog.Root>
  );
}

export default OrgChartButton;
