import { useContext } from '../Context';
import { useOrganizerAtom } from '../Provider';
import { PanelState } from '../types';
import { XMarkCircleIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { clsx } from 'clsx';
import { getDefaultAppIcon } from './icons';
import LinkButton from '../LinkButton';

function DefaultMenuList() {
  const { defaultItems } = useContext();
  const [organizerAtom, setOrganizerAtom] = useOrganizerAtom();

  const isCurrentApp = (appName: PanelState) => {
    return appName === organizerAtom.panelState;
  };
  const handleOnClickApp = (appName: PanelState) => {
    const isCurrent = isCurrentApp(appName);
    setOrganizerAtom((prev) => ({
      ...prev,
      organizerState: isCurrent ? 'default' : 'panel',
      panelState: isCurrent ? 'none' : appName,
    }));
  };

  return (
    <ul className="group_link_list">
      {defaultItems.map((item) => {
        const isCurrent = isCurrentApp(item.name as PanelState);

        return (
          <LinkButton
            className={clsx({
              'border-r-[3px] border-black': isCurrent,
            })}
            id={item.name}
            key={item.name}
            name={item.name}
            onClick={() => handleOnClickApp(item.name as PanelState)}
          >
            {isCurrent ? (
              <XMarkCircleIcon size={32} />
            ) : (
              <>{getDefaultAppIcon(item.name as PanelState)}</>
            )}
          </LinkButton>
        );
      })}
    </ul>
  );
}

export default DefaultMenuList;
