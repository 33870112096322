import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ConfigMenuInfo } from '../types';

const recentAppIdsAtom = atomWithStorage<ConfigMenuInfo[]>(
  'config.recentapps',
  [],
);

export const recentUsedAppsAtom = atom((get) => get(recentAppIdsAtom));

const maxRecentUsedApps = 5;
const maxAppIndex = maxRecentUsedApps - 1;

export const addRecentUsedAppAtom = atom(
  null,
  (get, set, update: ConfigMenuInfo) => {
    const recentAppIds = get(recentAppIdsAtom);
    if (recentAppIds.filter((info) => info.uid === update.uid).length > 0)
      return;
    const newAppIds = [update, ...recentAppIds.slice(0, maxAppIndex)];
    set(recentAppIdsAtom, newAppIds);
  },
);
