import * as fetchClient from '../../../lib/fetch/client';
import { API_AUTH_SIMPLE_CODE_URL } from './constants';

export async function checkSimpleCode(simpleCode: string, appCode: string) {
  try {
    const resp = await fetchClient.fetch(API_AUTH_SIMPLE_CODE_URL, {
      method: 'POST',
      headers: {
        'X-Referer-Info': window.location.hostname,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        simpleCode,
        appCode,
      }),
    });

    return resp.json();
  } catch (e) {
    console.error(e);
    return;
  }
}
