import { useAtom } from 'jotai';
import {
  ClockIcon,
  RectDotsIcon,
  StarIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { FAVORITEAPP_KEY, RECENTUSEDAPP_KEY } from '../../../constants';
import { recentUsedAppsAtom } from '../../../store/recentUsedAppsAtom';
import { favoriteAppsAtom } from '../../../store/favoriteAppsAtom';
import { serviceMapOpenStateAtom } from '../../../store/serviceMapOpenStateAtom';
import { appsAtom } from '../../../store/appsAtom';
import { Provider } from './Context';
import { SearchBar } from './SearchBar';
import { ConfigMenuInfoWithCount } from './MenuList/type';
import { MenuList } from './MenuList';

export function Main() {
  const { t } = useTranslation();
  const [{ data: favoriteApps }] = useAtom(favoriteAppsAtom);
  const [recentApps] = useAtom(recentUsedAppsAtom);
  const [{ data: apps }] = useAtom(appsAtom);
  const [, setServiceMapOpenState] = useAtom(serviceMapOpenStateAtom);

  const myApps: ConfigMenuInfoWithCount[] = [
    {
      uid: RECENTUSEDAPP_KEY,
      appCode: RECENTUSEDAPP_KEY,
      name: t('globalconfig.menu.recentUsedApp'),
      url: '/config/recent-used-app',
      disabled: false,
      icon: <ClockIcon />,
      sortOrder: 0,
      childList: recentApps,
    },
    {
      uid: FAVORITEAPP_KEY,
      appCode: FAVORITEAPP_KEY,
      name: t('globalconfig.menu.favoriteApp'),
      url: '/config/favorite-app',
      disabled: false,
      icon: <StarIcon />,
      sortOrder: 0,
      childList: favoriteApps ?? [],
      count: favoriteApps?.length ?? 0,
    },
  ];

  return (
    <Provider>
      <div className="pt-[50px] w-[240px] h-full px-[24px] border-r-[1px] flex-shrink-0">
        <h2 className="px-[8px] text-[#3B3B3B] text-[25.4px]">
          {t('globalconfig.title')}
        </h2>
        <SearchBar className="mt-[16px]" />
        <button
          className="flex items-center mt-[24px] pl-[8px] w-full bg-transparent text-[#3B3B3B]"
          onClick={() => setServiceMapOpenState(true)}
        >
          <RectDotsIcon />
          <span className="pl-[8px] text-[#3B3B3B]">
            {t('globalconfig.menu.title')}
          </span>
        </button>
        <div className="mt-[16px] mx-[8px] h-[1px] w-full bg-[#E5E5E5]" />
        <div className="px-[12px] py-[4px] mt-[16px] text-[#888888]">My</div>
        <MenuList className="mt-[4px]" menuList={myApps} />
        <div className="px-[12px] py-[4px] mt-[16px] text-[#888888]">
          Management
        </div>
        <MenuList className="mt-[4px]" menuList={apps ? apps : []} />
      </div>
    </Provider>
  );
}
