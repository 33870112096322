import * as fetchClient from '../../../lib/fetch/client';
import { API_SIMPLE_CODE_URL } from './constants';
import { ResponseBody } from './types';

export async function editSimpleCode(
  currentSimpleCode: string,
  simpleCode: string,
  checkSimpleCode: string,
) {
  try {
    const resp = await fetchClient.fetch(API_SIMPLE_CODE_URL, {
      method: 'PUT',
      headers: {
        'X-Referer-Info': window.location.hostname,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        currentSimpleCode,
        simpleCode,
        checkSimpleCode,
      }),
    });

    return resp.json() as ResponseBody<string>;
  } catch (e) {
    console.error(e);
    return {} as ResponseBody<string>;
  }
}
