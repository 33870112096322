import { AccessInfo } from './store';
import { AppInfo } from './types';

const MINUTES_TEN = 1000 * 60 * 10;
let timer: NodeJS.Timeout | undefined = undefined;

export const startFocusTimer = (onFinish?: () => void, timeout?: number) => {
  timeout && MINUTES_TEN - timeout < 0 && onFinish && onFinish();
};

const startTimer = (
  appInfo: AppInfo,
  accessInfoList: AccessInfo[],
  onSuccess: (...args: unknown[]) => void,
) => {
  const accessTime = accessInfoList.find(
    (info) => info.appId === appInfo.appId || info.appUrl === appInfo.appUrl,
  )?.accessTime;

  accessTime ?? clearTimer();
  if (
    accessTime &&
    new Date().getTime() - new Date(accessTime).getTime() > MINUTES_TEN
  ) {
    onSuccess(appInfo.appId, appInfo.appUrl, appInfo.appName);
  }
};

export const initTimer = (
  appInfo: AppInfo,
  accessInfoList: AccessInfo[],
  onSuccess: (...args: unknown[]) => void,
) => {
  timer && clearInterval(timer);
  timer = setInterval(
    () =>
      startTimer(appInfo, accessInfoList, () =>
        onSuccess(appInfo.appId, appInfo.appUrl, appInfo.appName),
      ),
    1000,
  );
};

export const clearTimer = () => timer && clearInterval(timer);
