import { ConfigMenuInfo } from '../types';

export interface MenuNames {
  mainCategoryName: string;
  subCategoryName?: string;
  appName: string;
}

function findAppInChildList(
  id: string,
  childList: ConfigMenuInfo[] | undefined,
  mainCategoryName: string,
  subCategoryName?: string,
): MenuNames | null {
  if (!childList) return null;

  for (const child of childList) {
    if (child.url === id) {
      return {
        mainCategoryName,
        subCategoryName: subCategoryName ? subCategoryName : undefined,
        appName: child.name,
      };
    }
  }
  return null;
}

export function getMenuNames(
  id: string,
  appList: ConfigMenuInfo[],
): MenuNames | undefined {
  for (const app of appList) {
    for (const subApp of app.childList || []) {
      if (subApp.url === id) {
        return {
          mainCategoryName: app.name,
          appName: subApp.name,
        };
      }
      const result = findAppInChildList(
        id,
        subApp.childList,
        app.name,
        subApp.name,
      );
      if (result) {
        return result;
      }
    }
  }
  return undefined;
}
