import { ActionButton } from './primitives/ActionButton';
import { Contents } from './primitives/Contents';
import { ErrorMessage } from './primitives/ErrorMessage';
import { InfoMessage } from './primitives/InfoMessage';
import { PinField } from './primitives/PinField';
import { Root } from './primitives/Root';
import { TextField } from './primitives/TextField';
import { Title } from './primitives/Title';

export { SimpleCodeFormClient as default } from './SimpleCodeFormClient';

export const SimpleCodeFormPrimitives = {
  Root,
  Contents,
  TextField,
  PinField,
  ActionButton,
  Title,
  InfoMessage,
  ErrorMessage,
};
