import { useTranslation } from '../../../../../../lib/i18n/client/useTranslation';

export default function NoMemoSearchResultTemplate() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-[800px]">
      <p className="text-[15px]">{t('organizer.memo.empty.search.result')}</p>
    </div>
  );
}
