import { MemoColors } from './types';

export const defaultTimeFormat = 'YYYY-MM-DD';
export const favoriteFalseColor = '#E5E5E5';
export const favoriteTrueColor = '#FFD700';

export const MemoColorClassNames: { [k in MemoColors]: string } = {
  yellow: 'bg-[#fef08a]',
  pink: 'bg-[#fbcfe8]',
  mint: 'bg-[#bbf7d0]',
  skyblue: 'bg-[#bfdbfe]',
  gray: 'bg-gray-200',
};
