import { atomWithQuery } from 'jotai-tanstack-query';
import { fetch } from '../../../lib/fetch/client';
import { configMenuToConfigMenuInfoadapter } from '../utils/menuUtils';
import { CONFIG_FAVORITE_APPS_API_URL } from '../constants';
import { ConfigMenuInfo, ConfigMenuResponse } from '../types';

async function getFavoriteConfigApps(): Promise<ConfigMenuInfo[]> {
  const response = await fetch(CONFIG_FAVORITE_APPS_API_URL);
  const responseData = (await response.json()) as ConfigMenuResponse;

  return (
    responseData.data?.menuList
      .sort((prev, next) => prev.sortOrder - next.sortOrder)
      .map(configMenuToConfigMenuInfoadapter)
      .filter(Boolean) ?? []
  );
}

export const CONFIG_FAVORITE_APPS_KEY = 'QueryKeys.Config.FavoriteApps';

export const favoriteAppsAtom = atomWithQuery(() => ({
  queryKey: [CONFIG_FAVORITE_APPS_KEY],
  queryFn: getFavoriteConfigApps,
}));
