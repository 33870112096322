import { Provider } from 'jotai';
import { PropsWithChildren } from 'react';
import { Container } from './Container';
import { globalConfigStore } from '../../store/globalConfigStore';

export interface Props {
  contextPath?: string;
  configPath?: string;
  onPageChange?: (pageURL: string) => void;
}

export function Root({ children, ...props }: PropsWithChildren<Props>) {
  return (
    <Provider store={globalConfigStore}>
      <Container {...props}>{children}</Container>
    </Provider>
  );
}

export default Root;
