import * as fetchClient from '../../../lib/fetch/client';
import { API_SCREEN_LOCK_SIMPLE_CODE_URL } from './constants';

export async function checkScreenLockTarget(appCode: string) {
  try {
    const resp = await fetchClient.fetch(API_SCREEN_LOCK_SIMPLE_CODE_URL, {
      method: 'PUT',
      headers: {
        'X-Referer-Info': window.location.hostname,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        appCode,
      }),
    });

    console.log(resp.json());

    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
}
