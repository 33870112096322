import { IconButton } from '@daouoffice/ui/lib/foundation/Button';
import * as Dialog from '@daouoffice/ui/lib/foundation/Dialog';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24/Close';
import { clsx } from 'clsx';
import { PropsWithChildren, useState } from 'react';
import { useScreenLockContext } from '../../lib/screen-lock/Provider';
import { GlobalConfigPrimitives } from './parts';

import '@daouoffice/design/dist/template/preferences.css';

export interface Props {
  asChild?: boolean;
}

export function GlobalConfigDialog({
  asChild = false,
  children,
}: PropsWithChildren<Props>) {
  const [openState, setOpenState] = useState(false);
  const { regist } = useScreenLockContext();

  return (
    <Dialog.Root
      modal={true}
      onOpenChange={(isOpened: boolean) => {
        setOpenState(isOpened);
      }}
    >
      <Dialog.Trigger
        asChild={asChild}
        onClick={() => regist(undefined, undefined, 'globalConfigDialog', true)}
      >
        {children}
      </Dialog.Trigger>
      <Dialog.Content
        className={clsx('bottom', { open: openState })}
        dim={true}
        size="full"
      >
        <GlobalConfigPrimitives.Root>
          <Dialog.Close asChild>
            <IconButton
              className="absolute top-[36px] right-[64px]"
              title="globalconfig.close"
            >
              <CloseIcon />
            </IconButton>
          </Dialog.Close>
          <GlobalConfigPrimitives.ServiceMap />
          <GlobalConfigPrimitives.AppMenu />
          <GlobalConfigPrimitives.Contents />
        </GlobalConfigPrimitives.Root>
      </Dialog.Content>
    </Dialog.Root>
  );
}
