import { useStack } from './Root';

export const usePush = <T>() => {
  const { setStack } = useStack();
  return (item: T) => {
    setStack((prevStack) => [...prevStack, item]);
  };
};

export const usePop = () => {
  const { setStack } = useStack();
  return () => {
    setStack((prevStack) => prevStack.slice(0, -1));
  };
};

export const useStackMethod = <T>() => {
  return { push: usePush<T>(), pop: usePop() };
};
