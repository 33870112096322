import { useMemo, useState } from 'react';
import {
  EditorContent,
  Link,
  Placeholder,
  StarterKit,
  TaskItem,
  TaskList,
  useEditor,
} from '@daouoffice/ui/lib/foundation/Editor';
import './memo-editor.css';

import { clsx } from 'clsx';
import { useMemoContext } from '../Context';
import { formatDate } from '../../../../../lib/utils/date';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as getMemoQuery from '../apis/getMemo';
import type { FavoriteInfo } from '../apis/updateMemoFavorite';

import { IconButton } from '@daouoffice/ui';
import MemoToolbar from './EditMenus/MemoToolbar';
import { StarIcon } from '@heroicons/react/24/outline';
import FormattingToolbar from './EditMenus/FormattingToolbar';
import { Templates, useToast } from '../../../../Toast';
import {
  favoriteFalseColor,
  favoriteTrueColor,
  MemoColorClassNames,
} from '../constants';
import { updateMemoFavorite } from '../apis/updateMemoFavorite';

function MemoEditor() {
  const {
    memoContext: {
      memoId,
      content,
      updatedAt,
      isFavorite,
      favoriteCount,
      color,
    },
    setMemoContext,
  } = useMemoContext();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [toolbarShow, setToolbarShow] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Placeholder,
      TaskItem.configure({ HTMLAttributes: { class: 'task-list-item' } }),
      TaskList,
      Link.configure({
        autolink: true,
        openOnClick: true,
      }),
    ],
    autofocus: true,

    editorProps: {
      attributes: {
        class: 'focus:outline-none',
      },
    },
    content: content,
    onUpdate({ editor }) {
      let updatedContent = editor.getHTML();
      if (editor.isEmpty) updatedContent = '';
      setMemoContext((prev) => ({
        ...prev,
        content: updatedContent,
        requiredSave: true,
      }));
    },
  });

  const { info } = useToast();
  const updateMemoFavoriteMutaion = useMutation({
    mutationFn: ({ id, isFavorite }: FavoriteInfo) =>
      updateMemoFavorite({ id, isFavorite }),
    onSuccess(data, variables) {
      const toastMessage = variables.isFavorite
        ? t('organizer.memo.toast.favorite.add')
        : t('organizer.memo.toast.favorite.cancel');

      info(<Templates.Basic description={toastMessage} />);
      void queryClient.invalidateQueries({
        queryKey: [getMemoQuery.QUERY_KEY, memoId],
      });
      setMemoContext((prev) => ({
        ...prev,
        isFavorite: variables.isFavorite,
      }));
    },
    onError(error) {
      info(
        <Templates.Basic
          description={error.message ?? t('organizer.memo.error')}
        />,
      );
    },
  });

  const handleClickStar = () => {
    if (!memoId) return;

    if (favoriteCount >= 5 && !isFavorite) {
      info(
        <Templates.Basic
          description={t('organizer.memo.toast.favorite.full')}
        />,
      );
    } else {
      updateMemoFavoriteMutaion.mutate({
        id: memoId,
        isFavorite: !isFavorite,
      });
    }
  };

  const editorFooter = useMemo(() => {
    return (
      <div className="flex justify-between items-center px-5 pb-2">
        {updatedAt && (
          <p className="text-gray-600">
            {`${t('organizer.memo.lastupdate')} : ${formatDate({ date: updatedAt, format: 'YYYY-MM-DD' })}`}
          </p>
        )}
        {memoId && (
          <IconButton title="" onClick={handleClickStar}>
            <StarIcon
              width={28}
              height={28}
              color={isFavorite ? favoriteTrueColor : favoriteFalseColor}
              fill={isFavorite ? favoriteTrueColor : favoriteFalseColor}
            />
          </IconButton>
        )}
      </div>
    );
  }, [memoId, isFavorite, updatedAt]);

  return (
    <>
      <div className="flex">
        {toolbarShow ? (
          <FormattingToolbar editor={editor} setToolbarShow={setToolbarShow} />
        ) : (
          <MemoToolbar editor={editor} setToolbarShow={setToolbarShow} />
        )}
      </div>
      <div
        className={clsx(
          'flex-1 rounded-md flex flex-col max-h-[calc(100%-56px)] h-[calc(100%-56px)]',
          MemoColorClassNames[color],
        )}
      >
        <EditorContent
          onClick={() => editor?.commands.focus()}
          spellCheck="false"
          className={clsx(
            'memo-editor-container h-full overflow-y-scroll flex-1 p-5 scroll-m-0',
          )}
          editor={editor}
        />

        {editorFooter}
      </div>
    </>
  );
}

export default MemoEditor;
