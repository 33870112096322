import { Button } from '@daouoffice/ui';
import { PreviousIndicatorIcon } from '@daouoffice/ui/lib/icons/dop/16';
import { SearchClearIcon, SearchIcon } from '@daouoffice/ui/lib/icons/dop/24';
import MemoSearchList from './MemoSearchList';
import {
  useStack,
  useStackMethod,
} from '@daouoffice/ui/lib/foundation/Stacker';
import { OrganizerMemoPages } from '..';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';
import * as getMemoList from '../apis/getMemoList';
import { useMemoContext } from '../Context';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function OrganizerMemoSearch() {
  const { stack } = useStack<OrganizerMemoPages>();
  const { pop } = useStackMethod<OrganizerMemoPages>();
  const canPop = stack.length > 1;

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { memoContext, setMemoContext } = useMemoContext();

  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    setKeyword(memoContext.keyword);
  }, [memoContext.keyword]);

  const updateSearchKeyword = (searchKeyword: string) => {
    setMemoContext({
      ...memoContext,
      keyword: searchKeyword,
    });
  };

  const updateSearch = (searchKeyword: string) => {
    updateSearchKeyword(searchKeyword);
    void queryClient.invalidateQueries({
      queryKey: [getMemoRecentlySearchList.QUERY_KEY],
    });
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      updateSearch(keyword);
    }
  };

  const handleClickPrevBtn = () => {
    pop();

    updateSearchKeyword('');
    void queryClient.invalidateQueries({
      queryKey: [getMemoList.QUERY_KEY],
    });
  };

  const handleClickClearBtn = () => {
    setKeyword('');
    updateSearch('');
  };

  const handleClickSearchBtn = () => {
    updateSearch(keyword);
  };

  return (
    <div className="organizer_cont card wrap_card open" id="organizer_cont">
      <div className="wrap_organizer_cont">
        <div className="wrap_organizer_header !pl-6">
          {canPop && (
            <Button
              title=""
              className="flex items-center"
              onClick={handleClickPrevBtn}
            >
              <PreviousIndicatorIcon />
            </Button>
          )}
          <div className="flex w-[230px] h-[40px] px-2 mx-1 bg-gray-200 rounded-md">
            <SearchIcon size={20} />
            <input
              type="text"
              className="w-[80%] mx-2 bg-gray-200"
              placeholder={t('organizer.memo.search.input')}
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={handleOnKeyDown}
            />
            {keyword != '' && (
              <Button
                title=""
                className="flex items-center bg-gray-200"
                onClick={handleClickClearBtn}
              >
                <SearchClearIcon size={18} />
              </Button>
            )}
          </div>
          <Button
            title={t('organizer.memo.button.search')}
            className=" !bg-black !w-[50px]"
            shape="rect"
            size="medium"
            styleType="solid"
            onClick={handleClickSearchBtn}
          />
        </div>
        <div className="wrap_organizer_list">
          <MemoSearchList />
        </div>
      </div>
    </div>
  );
}

export default OrganizerMemoSearch;
