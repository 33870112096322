import { ConfigMenu, ConfigMenuInfo } from '../types';

export function configMenuToConfigMenuInfoadapter(
  src: ConfigMenu,
): ConfigMenuInfo {
  return {
    uid: src.menuId.toString(),
    appCode: src.appCode,
    name: src.menuName,
    disabled: src.disabled,
    url: src.url,
    icon: src.icon,
    sortOrder: src.sortOrder,
    childList: src.children
      ? src.children.map(configMenuToConfigMenuInfoadapter)
      : [],
  };
}
