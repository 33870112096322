import RecentlySearchItem from './RecentlySearchItem';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';
import { useSuspenseQuery } from '@tanstack/react-query';
import NoMemoSearchTemplate from './NoDataTemplate/NoMemoSearchTemplate';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';

export function RecentlySearchList() {
  const { t } = useTranslation();

  const {
    data: { data: memoRecentlySearchList },
    error,
  } = useSuspenseQuery({
    queryKey: [getMemoRecentlySearchList.QUERY_KEY],
    queryFn: async () =>
      await getMemoRecentlySearchList.getMemoRecentlySearchList(),
  });

  if (error) {
    console.error(error);
    return;
    // throw error
  }

  return (
    <div>
      <div className="text-gray-400">{t('organizer.memo.search.recently')}</div>
      <ul>
        {memoRecentlySearchList?.length > 0 ? (
          memoRecentlySearchList?.map((item) => (
            <RecentlySearchItem key={item.id} searchItem={item} />
          ))
        ) : (
          <NoMemoSearchTemplate />
        )}
      </ul>
    </div>
  );
}

export default RecentlySearchList;
