import { Button } from '@daouoffice/ui';
import { buttonType } from '../types';

export interface Props {
  title: string;
  className?: string;
  type?: buttonType;
  handleOnAction: () => void;
}

export function ActionButton({
  title,
  className,
  type = 'positive',
  handleOnAction,
}: Props) {
  return (
    <Button
      title={title}
      shape="rect"
      size="large"
      styleType={type === 'positive' ? 'solid' : 'line'}
      className={className}
      tabIndex={0}
      onClick={handleOnAction}
    />
  );
}

export default ActionButton;
