import { Main } from './Main';
import { Sub } from './Sub';

export interface Props {}

export function AppMenu() {
  return (
    <div className="flex h-full w-[500px] bg-[#F8F9FA]">
      <Main />
      <Sub />
    </div>
  );
}

export default AppMenu;
