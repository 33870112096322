import { IconButton } from '@daouoffice/ui';
import { StarIcon as UnFavoriteIcon } from '@heroicons/react/24/outline';
import { StarIcon as FavoriteIcon } from '@heroicons/react/24/solid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import {
  addFavoriteMenu,
  removeFavoriteMenu,
} from '../../../../../lib/gnb/menu/menuAPI';
import {
  CONFIG_FAVORITE_APPS_KEY,
  favoriteAppsAtom,
} from '../../../store/favoriteAppsAtom';
import { selectedAppIdAtom } from '../../../store/selectedAppIdAtom';

export function FavoriteButton() {
  const selecteAppId = useAtomValue(selectedAppIdAtom);
  const [{ data: favoriteApps }] = useAtom(favoriteAppsAtom);
  const [isChecked, setCheckState] = useState(false);
  const queryClient = useQueryClient();
  const platform = 'SETTING';

  const addMutation = useMutation({
    mutationFn: () => {
      return addFavoriteMenu(platform, Number(selecteAppId));
    },
    onSuccess: () => {
      setCheckState(true);
      void queryClient.invalidateQueries({
        queryKey: [CONFIG_FAVORITE_APPS_KEY],
      });
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  const removeMutaion = useMutation({
    mutationFn: () => {
      return removeFavoriteMenu(platform, Number(selecteAppId));
    },
    onSuccess: () => {
      setCheckState(false);
      void queryClient.invalidateQueries({
        queryKey: [CONFIG_FAVORITE_APPS_KEY],
      });
    },
    onError: (err) => {
      alert(err.message);
    },
  });

  const onClickHandler = () => {
    if (isChecked) {
      removeMutaion.mutate();
    } else {
      if (favoriteApps && favoriteApps.some((app) => app.uid !== selecteAppId))
        addMutation.mutate();
    }
  };

  useEffect(() => {
    favoriteApps &&
      setCheckState(favoriteApps.some((app) => app.uid === selecteAppId));
  }, [favoriteApps, selecteAppId]);

  return (
    <IconButton title="favorite_btn" className="!ml-2" onClick={onClickHandler}>
      {isChecked ? (
        <FavoriteIcon width={24} height={24} className="fill-[#27E1FB]" />
      ) : (
        <UnFavoriteIcon width={24} height={24} />
      )}
    </IconButton>
  );
}

export default FavoriteButton;
