import { atom } from 'jotai';

import * as React from 'react';
import * as Jotai from 'jotai';
import { IOrganizerAtom } from './types';

const store = Jotai.createStore();

export function Provider({ children }: React.PropsWithChildren) {
  return <Jotai.Provider store={store}>{children}</Jotai.Provider>;
}

export const organizerAtom = atom<IOrganizerAtom>({
  organizerState: 'default',
  panelState: 'none',
  settingMenuOpenState: false,
});

export const useOrganizerValuesAtom = () => Jotai.useAtomValue(organizerAtom);

export const useOrganizerAtom = () => Jotai.useAtom(organizerAtom);
