import OrganizerCalendar from './Calendar';
import OrganizerMail from './Mail';
import { useOrganizerValuesAtom } from '../Provider';
import OrganizerShortcut from './Shortcut';
import OrganizerMemo from './Memo';
import { Provider as ShortcutProvider } from './Shortcut/Context';
import DefaultAppContainer from './DefaultAppContainer';

function OrganizerDefaultApp() {
  const { panelState } = useOrganizerValuesAtom();

  return (
    panelState !== 'none' && (
      <>
        <DefaultAppContainer visible={panelState === 'shortcut'}>
          <ShortcutProvider>
            <OrganizerShortcut />
          </ShortcutProvider>
        </DefaultAppContainer>

        <DefaultAppContainer visible={panelState === 'memo'}>
          <OrganizerMemo />
        </DefaultAppContainer>

        <DefaultAppContainer visible={panelState === 'mail'}>
          <OrganizerMail />
        </DefaultAppContainer>

        <DefaultAppContainer visible={panelState === 'calendar'}>
          <OrganizerCalendar />
        </DefaultAppContainer>
      </>
    )
  );
}

export default OrganizerDefaultApp;
