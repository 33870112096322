import { clsx } from 'clsx';
import { PropsWithChildren, useEffect, useRef } from 'react';

interface Props {
  visible: boolean;
}
function DefaultAppContainer({ visible, children }: PropsWithChildren<Props>) {
  const isOpened = useRef(false);
  useEffect(() => {
    if (visible) {
      isOpened.current = true;
    }
  }, [visible]);

  return (
    isOpened && (
      <div className={clsx('w-full h-full', { hidden: !visible })}>
        {children}
      </div>
    )
  );
}

export default DefaultAppContainer;
