import { MemoType } from '../types';
import MemoItem from './MemoItem';

interface Props {
  memoList: MemoType[];
}

export function MemoList({ memoList }: Props) {
  return (
    <ul>
      {memoList.map((memoItem) => (
        <MemoItem key={memoItem.id} memo={memoItem} />
      ))}
    </ul>
  );
}

export default MemoList;
