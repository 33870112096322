import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import {
  ClearIcon,
  InputInvisibleIcon,
  InputVisibleIcon,
} from '@daouoffice/ui/lib/icons/dop/24';
import { clsx } from 'clsx';
import { KeyboardEvent, PropsWithChildren, useState } from 'react';

export type TextFieldType = 'text' | 'password';

export interface Props {
  title?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  onTextChange?: (text: string) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export function TextField({
  title,
  value = '',
  placeholder,
  className,
  onKeyPress,
  onTextChange,
}: PropsWithChildren<Props>) {
  const [isVisible, setIsVisible] = useState(false);
  const [type, setType] = useState('password');

  const handleVisibleBtnClick = () => {
    if (isVisible) setType('password');
    else setType('text');
    setIsVisible(!isVisible);
  };

  const clearButtonBlock = (
    <div className="absolute right-2" tabIndex={-1}>
      <IconButton
        title="clear"
        onClick={() => onTextChange && onTextChange('')}
        tabIndex={-1}
      >
        <ClearIcon size={12} />
      </IconButton>
    </div>
  );

  const visibleButtonBlock = (
    <div className="absolute right-10" tabIndex={-1}>
      <IconButton title="clear" onClick={handleVisibleBtnClick} tabIndex={-1}>
        {isVisible ? (
          <InputVisibleIcon size={12} />
        ) : (
          <InputInvisibleIcon size={12} />
        )}
      </IconButton>
    </div>
  );

  const inputBlock = (
    <div className="relative flex items-center">
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onTextChange && onTextChange(e.target.value)}
        className={clsx(
          'w-[378px] p-4 border border-solid border-[#ddd] rounded-md mb-[4px] mt-[4px] text-black placeholder-[#ddd]',
          className,
        )}
        type={type}
        inputMode="numeric"
        onKeyDown={onKeyPress}
      />
      {value.length !== 0 && visibleButtonBlock}
      {value.length !== 0 && clearButtonBlock}
    </div>
  );

  const titleBlock = title && <span className="font-bold">{title}</span>;

  return (
    <div className={className}>
      {titleBlock}
      {inputBlock}
    </div>
  );
}

export default TextField;
