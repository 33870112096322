import { createInstance } from 'i18next';
import type { Resource, ReadCallback, ResourceKey } from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next';
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend';
import { type HttpBackendOptions } from 'i18next-http-backend';
import i18nConfig from './config';

type ImportFn = 
  | ((language: string, namespace: string, callback: ReadCallback) => void) 
  | ((language: string, namespace: string) => Promise<ResourceKey | boolean | null | undefined>)

export interface Params {
  locale: string;
  namespaces: string[];
  config?: typeof i18nConfig;
  loadResource?: Resource | ImportFn;
  backendOptions?: HttpBackendOptions;
}

export async function initTranslations({
  locale, 
  namespaces,
  config = i18nConfig,
  loadResource,
  backendOptions
}: Params) {
  const i18nInstance = createInstance();

  await i18nInstance
    .use(initReactI18next)
    .use(ChainedBackend)
    .init({
      lng: locale,
      fallbackLng: config.defaultLocale,
      supportedLngs: config.locales,
      defaultNS: namespaces[0],
      fallbackNS: namespaces[0],
      ns: namespaces,
      preload: typeof window === 'undefined' ? config.locales : [],
      backend: {
        backends: [
          // HttpBackend,
          loadResource ? resourcesToBackend(loadResource) : null
        ].filter(Boolean),
        backendOptions
      }
    });

  return i18nInstance;
}

export default initTranslations;
