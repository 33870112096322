/* eslint-disable @typescript-eslint/no-misused-promises */
import { ChangeEvent, SetStateAction, useRef } from 'react';
import { useContext } from '../../Context';
import { ShortcutItemInfo, ShortcutItemType } from '../../index';
import { SettingsMenuItem } from '../SettingsMenuItem';
import { IdentifiableShortcutItem } from '../../DefaultApp/Shortcut/types';

export interface Props {
  /** @property 클래스네임 */
  className: string;
  /** @property Shortcut 삭제 반영 함수 */
  setDeletedItemList: React.Dispatch<
    SetStateAction<IdentifiableShortcutItem[]>
  >;
}

export function SettingsMenuItemList({ className, setDeletedItemList }: Props) {
  const ctx = useContext();

  const dragItem = useRef<number>();
  const dragOverItem = useRef<number>();

  const dragStart = (e: React.DragEvent<HTMLElement>, position: number) => {
    dragItem.current = position;
  };

  const dragEnter = (e: React.DragEvent<HTMLElement>, position: number) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const newList = [...ctx.settingsItems];
    const dragItemValue: ShortcutItemInfo = newList[
      dragItem.current as number
    ] as ShortcutItemInfo;
    newList.splice(dragItem.current as number, 1);
    newList.splice(dragOverItem.current as number, 0, dragItemValue);
    dragItem.current = undefined;
    dragOverItem.current = undefined;
    ctx.setSettingsItems(newList);
  };

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement>,
    id: number,
    itemType: ShortcutItemType,
  ) => {
    if (itemType === 'APP') return;
    const { value } = e.target;

    ctx.setSettingsItems((prev) =>
      prev.map((item) => {
        if (item.uid === id) {
          const updated = { ...item };
          updated.name = value;
          return updated;
        } else return item;
      }),
    );
  };

  const handleOnDelete = (uid: number, type: ShortcutItemType) => {
    ctx.setSettingsItems((prev) => {
      const res = prev.filter(
        (item) => !(item.uid === uid && item.itemType === type),
      );
      return res;
    });
    setDeletedItemList((prev) => [
      ...prev,
      {
        id: uid,
        type: type,
      },
    ]);
  };

  return (
    <ul className={className} style={{ height: `calc(100% - 180px)` }}>
      {ctx.settingsItems.map((item, index) => {
        return (
          <SettingsMenuItem
            item={item}
            itemType={item.itemType}
            key={index}
            index={index}
            onDragStart={dragStart}
            onDragEnter={dragEnter}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={drop}
            onChange={handleOnChange}
            onDelete={handleOnDelete}
          />
        );
      })}
    </ul>
  );
}

export default SettingsMenuItemList;
