'use client';

import type { PropsWithChildren } from 'react';
import type { i18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { useEffect, useState } from 'react';
import {
  initTranslations,
  type Params as TranslationsInitializerParams,
} from '../initTranslations';

export interface Props extends TranslationsInitializerParams {}

export function Provider({
  children,
  ...initParams
}: PropsWithChildren<Props>) {
  const [instance, setInstance] = useState<i18n | null>(null);
  const { locale, namespaces } = initParams;

  useEffect(() => {
    const init = async () => {
      if (!instance) {
        const newInstance = await initTranslations(initParams);
        setInstance(newInstance);
      } else if (instance.language !== locale) {
        await instance.changeLanguage(locale);
      }
    };

    init().catch((e) => console.log(e));
  }, [instance, locale, initParams]);

  if (!instance) {
    return null;
  }

  return (
    <I18nextProvider i18n={instance} defaultNS={namespaces[0]}>
      {children}
    </I18nextProvider>
  );
}

export default Provider;
