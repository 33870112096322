import * as RadixPrimivites from '@radix-ui/react-tooltip';

export const Provider = RadixPrimivites.Provider;
export const Root = RadixPrimivites.Root;
export const Trigger = RadixPrimivites.Trigger;

export type ProviderProps = RadixPrimivites.TooltipProviderProps;

export interface PreviewToolipContentProps
  extends RadixPrimivites.TooltipContentProps {
  subject?: string;
  preview?: string;
}

export function Content({
  children,
  side = 'bottom',
  align = 'start',
  ...props
}: RadixPrimivites.TooltipContentProps) {
  return (
    <RadixPrimivites.Portal>
      <RadixPrimivites.Content
        side={side}
        align={align}
        className="bg-[#595959] text-white rounded-lg px-5 py-2"
        {...props}
      >
        {children}
      </RadixPrimivites.Content>
    </RadixPrimivites.Portal>
  );
}

export function PreviewContent({
  side = 'bottom',
  align = 'start',
  subject,
  preview,
}: PreviewToolipContentProps) {
  return (
    <RadixPrimivites.Portal>
      <RadixPrimivites.Content side={side} align={align}>
        <dl id="previewContentLayer" className="preview">
          {subject && <dt>{subject}</dt>}
          {preview && <dd>{preview}</dd>}
        </dl>
      </RadixPrimivites.Content>
    </RadixPrimivites.Portal>
  );
}
