import { useAtomValue } from 'jotai';
import {
  BackUpDataPage,
  CurrentDataUsagePage,
  DataRequestManagementPage,
  DeptDataManagePage,
  MemberDataManagePage,
  SetUsageNotificationPage,
  UploadDataManagementPage,
  UploadPolicyManagementPage,
} from '../../pages';
import { DeletedDeptDataManage } from '../../pages/dataManagement/DeletedDeptDataManage';
import { selectedAppIdAtom } from '../../store/selectedAppIdAtom';
import { Header } from './Header/index';
import { CONFIG_APP_URLS } from '../appURLs';

export function Contents() {
  const selectedAppId = useAtomValue(selectedAppIdAtom);

  // TODO: 명확한 메뉴 구분 기준이 확정 된 이후 해당 부분을 수정해야 합니다.
  /// 현행 : menuID 로 구분하고 있으나, 회사마다 menuID는 달라지기에 변경이 필요합니다.
  /// 수정 예상 : menuID 대신, appCode 혹은 url을 이용하게 될 것 같습니다. 논의 및 확정 후 알려주시기로 하였습니다. (예정일 : 24.07.18)
  const renderPage = () => {
    switch (selectedAppId) {
      case CONFIG_APP_URLS.DATA_MANAGEMENT.CURRENT_DATA_USAGE:
        return <CurrentDataUsagePage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.SET_USAGE_NOTIFICATION:
        return <SetUsageNotificationPage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.DATA_REQUEST_MANAGEMENT:
        return <DataRequestManagementPage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.UPLOAD_POLICY_MANAGEMENT:
        return <UploadPolicyManagementPage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.UPLOAD_DATA_MANAGEMENT:
        return <UploadDataManagementPage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.DEPT_DATA_MANAGEMENT:
        return <DeptDataManagePage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.DELETED_DEPT_DATA_MANAGEMENT:
        return <DeletedDeptDataManage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.MEMBER_DATA:
        return <MemberDataManagePage />;
      case CONFIG_APP_URLS.DATA_MANAGEMENT.BACK_UP_DATA:
        return <BackUpDataPage />;
    }
  };

  return (
    <div className="size-full overflow-auto">
      <Header />
      {renderPage()}
    </div>
  );
}

export default Contents;
