/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { IconButton } from '@daouoffice/ui';
import { SearchIcon, XMarkIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { MemoRecentlySearchType } from '../apis/getMemoRecentlySearchList';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMemoRecentlySearch } from '../apis/deleteMemoRecentlySearch';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';
import { useMemoContext } from '../Context';

interface Props {
  searchItem: MemoRecentlySearchType;
}

export function RecentlySearchItem({ searchItem }: Props) {
  const queryClient = useQueryClient();

  const { memoContext, setMemoContext } = useMemoContext();

  const deleteRecentlySearchMutaion = useMutation({
    mutationFn: (id: number) => deleteMemoRecentlySearch(id),
    onSuccess() {
      void queryClient.invalidateQueries({
        queryKey: [getMemoRecentlySearchList.QUERY_KEY],
      });
    },
    onError(error) {
      console.log(error.message);
    },
  });

  const handleClickResult = () => {
    setMemoContext({
      ...memoContext,
      keyword: searchItem.keyword,
    });
  };

  const handleClickXMark = (e?: React.MouseEvent<HTMLElement>) => {
    if (!e) {
      return;
    }
    e.stopPropagation();
    deleteRecentlySearchMutaion.mutate(searchItem.id);
  };

  return (
    <li
      className="flex items-center h-[35px] my-1 cursor-pointer"
      onClick={handleClickResult}
    >
      <SearchIcon size={20} />
      <span className="flex-grow mx-2 text-[15px]">{searchItem.keyword}</span>
      <IconButton
        title=""
        size="small"
        className="flex justify-between items-center"
        onClick={handleClickXMark}
      >
        <XMarkIcon size={18} />
      </IconButton>
    </li>
  );
}

export default RecentlySearchItem;
