import * as React from 'react';
import clsx from 'clsx';

import '@daouoffice/design/dist/foundation/tooltip.css';

export type TooltipDirection = 'left' | 'right' | 'bottom' | 'top';

/**
 * Tooltip 컴포넌트 속성
 */
export interface Props extends React.HTMLAttributes<HTMLElement> {
  as?: string;
  /** @property 툴팁 텍스트 */
  text: string;
  /** @property 추가 클래스 정의 */
  className?: string;
  /** @property 툴팁 표시 방향 */
  direction?: TooltipDirection;
}

/**
 * Tooltip 컴포넌트
 * @returns
 */
export function TooltipWith({
  as,
  children,
  className,
  text,
  direction = 'bottom',
  ...htmlAttrs
}: React.PropsWithChildren<Props>) {
  const Comp = (as ? as : 'div') as unknown as React.ElementType;

  return (
    <Comp className={clsx('has_tooltip', className)} {...htmlAttrs}>
      {children}
      <span
        className={clsx('tips', {
          top: direction === 'top',
          right: direction === 'right',
          bottom: direction === 'bottom',
          left: direction === 'left',
        })}
      >
        {text}
      </span>
    </Comp>
  );
}

export default TooltipWith;
