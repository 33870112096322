'use client';
import SimpleCodeFormClient from '@daouoffice/next/components/SimpleCodeForm';
import { useScreenLockContext } from '@daouoffice/next/lib/screen-lock/Provider';
import { PropsWithChildren } from 'react';
import { DOPContainer } from './DOPContainer';
import { GlobalNavBar } from './GlobalNavBar';
import { Header } from './Header';
import { Organizer } from './Organizer';

export function MainContainer({ children }: PropsWithChildren) {
  const { isLocked, appInfo, setLockState } = useScreenLockContext();

  return (
    <DOPContainer>
      {/* header: 로고, 통합 검색, SNB 영역 위치 */}
      <Header />

      {/* 개별 앱 컨테이너 */}
      <div className="wrap_body">
        {/* GNB */}
        <GlobalNavBar />

        {/* 메인 컨텐츠 영역 */}
        {isLocked && appInfo ? (
          <div className="h-full grid justify-center content-center">
            <SimpleCodeFormClient
              title={appInfo?.appName ?? ''}
              appCode={appInfo?.appId}
              onSuccess={() => setLockState(false)}
            />
          </div>
        ) : (
          <main className="wrap_contents">{children}</main>
        )}

        {/* 오거나이저 영역 */}
        <Organizer />
      </div>
    </DOPContainer>
  );
}

export default MainContainer;
