import TabList from './TabList';
import WorksList from './WorksList';
import LinkPlusList from './LinkPlusList';
import { useTranslation } from 'react-i18next';
import { Suspense } from 'react';

export function OrganizerAppAdder() {
  const { t } = useTranslation();

  return (
    <div>
      <p className="my-3 ml-2 text-[16px] font-medium">
        {t('organizer.shortcut.app')}
      </p>
      <TabList />
      <Suspense fallback={<div>Loading...</div>}>
        <WorksList />
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        <LinkPlusList />
      </Suspense>
    </div>
  );
}

export default OrganizerAppAdder;
