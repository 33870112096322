import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { CloseIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useContext } from '../Context';

import { SettingsMenuItemList } from './SettingsMenuItemList';
import { useOrganizerAtom } from '../Provider';
import { QUERY_KEY as getOrganizerQueryId } from '../apis/getOrganizerQuery';
import {
  ReArrangeInput,
  rearrangeShortcutQuery,
} from '../DefaultApp/Shortcut/api/rearrangeShortcutListMutation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteAllShortcut } from '../DefaultApp/Shortcut/api/deleteAllShortcutMutation';
import { useToast, Templates } from '../../Toast';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import {
  IdentifiableShortcutItem,
  UpdatedLinkItem,
} from '../DefaultApp/Shortcut/types';

export function SettingsMenu() {
  const queryClient = useQueryClient();
  const { info } = useToast();

  const [deletedList, setDeletedLIst] = useState<IdentifiableShortcutItem[]>(
    [],
  );
  const { t } = useTranslation();
  const ctx = useContext();

  const [, setOrganizerAtom] = useOrganizerAtom();

  const handleOnError = (message: string) => {
    info(<Templates.Basic description={`${message}`} />);
  };

  const generateReArrangeInput = (): ReArrangeInput => {
    const updatedLinkList: UpdatedLinkItem[] = [];

    ctx.shortcutItems
      .filter((item) => item.itemType === 'LINK')
      .forEach((item) => {
        const updatedItem = ctx.settingsItems.find(
          (shortcut) =>
            shortcut.uid === item.uid && shortcut.name !== item.name,
        );
        if (updatedItem) {
          updatedLinkList.push({
            id: updatedItem.uid,
            link: updatedItem.name,
          });
        }
      });

    return {
      sortedList: ctx.settingsItems.map((item) => ({
        id: item.uid,
        type: item.itemType,
      })),
      deletedList,
      updatedLinkList,
    };
  };

  const saveMutation = useMutation({
    mutationFn: async () =>
      await rearrangeShortcutQuery(generateReArrangeInput()),
    onSuccess: (data) => {
      const { errors } = data;
      if (errors && errors[0]) {
        handleOnError(errors[0].message);
      } else {
        handleOnSaveSuccess();
      }
    },
    onError: (error) => {
      handleOnError(error.message);
    },
  });

  const handleOnSaveSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [getOrganizerQueryId],
    });
    setDeletedLIst([]);
    setOrganizerAtom((prev) => ({
      ...prev,
      settingMenuOpenState: false,
    }));
    info(
      <Templates.Basic
        description={t('organizer.shortcut.setting.toast.save')}
      />,
    );
  };

  const isSettingItemsChanged = () => {
    const { settingsItems, shortcutItems } = ctx;
    if (settingsItems.length !== shortcutItems.length) return true;

    for (let i = 0; i < settingsItems.length; i++) {
      const setting = settingsItems[i]!;
      const shortcut = shortcutItems[i]!;
      if (setting.uid !== shortcut.uid) return true;
      if (setting.name !== shortcut.name) return true;
    }
    return false;
  };

  const handleOnClickCancel = () => {
    if (
      isSettingItemsChanged() &&
      !confirm(t('organizer.setting.alert.cancel'))
    ) {
      return;
    }
    ctx.setSettingsItems(ctx.shortcutItems);
    setOrganizerAtom((prev) => ({
      ...prev,
      settingMenuOpenState: false,
    }));
  };

  // 앱 바로가기 전체삭제 기능 제거 요청에 따른 주석처리
  // const deleteAllMutation = useMutation({
  //   mutationFn: async () => await deleteAllShortcut(),
  //   onSuccess: () => {
  //     handleOnDeleteSuccess();
  //   },
  //   onError: (error) => {
  //     handleOnError(error.message);
  //   },
  // });

  // const handleOnClickDeleteAll = () => {
  //   if (confirm(t('organizer.shortcut.setting.alert.delete.all'))) {
  //     deleteAllMutation.mutate();
  //   }
  // };
  // const handleOnDeleteSuccess = () => {
  //   void queryClient.invalidateQueries({
  //     queryKey: [getOrganizerQueryId],
  //   });
  //   setOrganizerAtom((prev) => ({
  //     ...prev,
  //     settingMenuOpenState: false,
  //   }));
  //   info(
  //     <Templates.Basic
  //       description={t('organizer.shortcut.setting.toast.delete.all')}
  //     />,
  //   );
  // };

  return (
    <div className="menu_setting open">
      <div className="wrap_menu_setting">
        <div className="wrap_menu_header">
          <h3 className="">{t('organizer.shortcut.setting')}</h3>
          <IconButton
            title={t('organizer.shortcut.setting.button.close')}
            size="small"
            onClick={handleOnClickCancel}
          >
            <CloseIcon className="ic_medium" />
          </IconButton>
        </div>
        <p className="text-[#878787] pl-[28px] my-2">
          {t('organizer.shortcut.setting.desc')}
        </p>

        {/* <div className="flex justify-end pr-[28px]">
          <Button
            title={t('organizer.shortcut.setting.button.all.delete')}
            className="px-2 py-1 rounded-md bg-gray-300 ml-auto my-5"
            onClick={handleOnClickDeleteAll}
          />
        </div> */}

        <SettingsMenuItemList
          setDeletedItemList={setDeletedLIst}
          className="orgnz-sortable-list px-5 overflow-y-scroll"
        />

        <div className="group_btn half">
          <Button
            title={t('organizer.shortcut.setting.button.cancel')}
            size="medium"
            shape="rect"
            styleType="line"
            onClick={handleOnClickCancel}
          />
          <Button
            title={t('organizer.shortcut.setting.button.save')}
            size="medium"
            shape="rect"
            styleType="solid"
            onClick={() => saveMutation.mutate()}
          />
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;
