import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useOrganizerValuesAtom } from '../../Provider';
import OrganizerLinkAdder from './OrganizerLinkAdder';
import OrganizerAppAdder from './OrganizerAppAdder';

export function OrganizerShortcut() {
  const { panelState } = useOrganizerValuesAtom();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'organizer_cont card wrap_card',
        panelState === 'shortcut' ? 'open' : '',
      )}
      id="organizer_cont"
    >
      <div className="wrap_organizer_cont">
        <div className="wrap_organizer_header">
          <h3 className="heading_medium bold">{t('organizer.shortcut')}</h3>
        </div>
        <div className="wrap_organizer_list">
          <OrganizerLinkAdder />
          <hr className="m-0" />
          <OrganizerAppAdder />
        </div>
      </div>
    </div>
  );
}

export default OrganizerShortcut;
