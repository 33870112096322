/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { useContext } from '../Context';

export function TabList() {
  const { t } = useTranslation();

  const { tab, setTab } = useContext();
  const tabList = ['All', 'Works', 'LinkPlus'];

  return (
    <ul className="flex">
      {tabList.map((tabName) => (
        <li
          key={tabName}
          className={clsx(
            'px-3 py-1 mx-2 border rounded-full',
            tabName === tab ? 'bg-black' : 'bg-white',
          )}
          onClick={() => {
            setTab(tabName);
          }}
        >
          <a
            className={clsx(tabName === tab ? ' text-white' : ' text-black')}
            title={tabName}
          >
            {t(`organizer.tab.${tabName.toLowerCase()}`)}
          </a>
        </li>
      ))}
    </ul>
  );
}

export default TabList;
