/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { clsx } from 'clsx';
import { IconButton } from '@daouoffice/ui';
import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import { StarIcon as StarSolidIcon } from '@daouoffice/ui/lib/icons/heroicons/24/solid';
import { OrganizerMemoPages } from '..';
import { formatDate } from '../../../../../lib/utils/date';
import {
  defaultTimeFormat,
  favoriteFalseColor,
  favoriteTrueColor,
  MemoColorClassNames,
} from '../constants';
import { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FavoriteInfo, updateMemoFavorite } from '../apis/updateMemoFavorite';
import { QUERY_KEY as getMemoListQueryId } from '../apis/getMemoList';
import { useMemoContext } from '../Context';
import { Templates, useToast } from '../../../../Toast';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { MemoType } from '../types';
import '../MemoEdit/memo-editor.css';
import * as getMemoQuery from '../apis/getMemo';
import { QUERY_KEY as getMemoSearchQueryId } from '../apis/getMemoSearch';
import { searchHilight } from '../utils';

interface Props {
  memo: MemoType;
}

// 메모 line 제한 계산
// Todo : 디자인팀 문의 필요
const memoHeight = 200;
const memoHeightStyle = `h-[${memoHeight}px]`;
const defaultLineHeight = 21;
const footerHeight = 40;
const memoContentHeight = memoHeight - footerHeight;
const defaultLineNo = Math.floor(memoContentHeight / defaultLineHeight);

export function MemoItem({ memo }: Props) {
  const { push } = useStackMethod<OrganizerMemoPages>();

  const { t } = useTranslation();
  const { memoContext, setMemoContext } = useMemoContext();

  const queryClient = useQueryClient();

  const { info } = useToast();

  const textRef = useRef<HTMLDivElement>(null);

  const [lineNo, setLineNo] = useState(defaultLineNo);
  const [displayContent, setDisplayContent] = useState(memo.content);

  useEffect(() => {
    const elm = textRef.current;
    if (!elm) return;

    let resizeObserver: ResizeObserver;

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          resizeObserver = new ResizeObserver(() => {
            if (elm.clientHeight > memoContentHeight) {
              setLineNo((prev) => prev - 1);
            }
          });
          resizeObserver.observe(elm);
        } else {
          if (resizeObserver) {
            resizeObserver.disconnect();
          }
        }
      });
    };

    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      root: null,
    });

    intersectionObserver.observe(elm);

    return () => {
      intersectionObserver.disconnect();
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [memo.content]);

  useEffect(() => {
    if (memoContext.keyword !== '') {
      setDisplayContent(searchHilight(memoContext.keyword, memo.content));
    } else {
      setDisplayContent(memo.content);
    }
  }, [memoContext.keyword, memo.content]);

  const updateMemoFavoriteMutaion = useMutation({
    mutationFn: ({ id, isFavorite }: FavoriteInfo) =>
      updateMemoFavorite({ id, isFavorite }),
    onSuccess(data, variables) {
      if (variables.isFavorite) {
        info(
          <Templates.Basic
            description={t('organizer.memo.toast.favorite.add')}
          />,
        );
      } else {
        info(
          <Templates.Basic
            description={t('organizer.memo.toast.favorite.cancel')}
          />,
        );
      }
      listInvalidateQuery();
      if (memoContext.keyword !== '') {
        void queryClient.invalidateQueries({
          queryKey: [getMemoSearchQueryId],
        });
      }
    },
    onError(error) {
      info(<Templates.Basic description={error.message} />);
      listInvalidateQuery();
    },
  });

  const handleClickStar = (e?: React.MouseEvent<HTMLElement>) => {
    if (!e) {
      return;
    }
    e.stopPropagation();

    if (memoContext.favoriteCount >= 5 && !memo.isFavorite) {
      info(
        <Templates.Basic
          description={t('organizer.memo.toast.favorite.full')}
        />,
      );
    } else {
      updateMemoFavoriteMutaion.mutate({
        id: memo.id,
        isFavorite: !memo.isFavorite,
      });
    }
  };

  const handleOnClick = async () => {
    try {
      const { data, message } = await queryClient.fetchQuery({
        queryKey: [getMemoQuery.QUERY_KEY, memo.id],
        queryFn: async () => await getMemoQuery.getMemo(memo.id),
      });

      if (data) {
        const { content, color, updatedAt, isFavorite } = data;
        setMemoContext((prev) => ({
          ...prev,
          memoId: memo.id,
          content,
          color,
          updatedAt,
          isFavorite,
        }));
        push('memo');
      } else {
        info(
          <Templates.Basic
            description={message ?? t('organizer.memo.error')}
          />,
        );
        listInvalidateQuery();
      }
    } catch (e) {
      info(<Templates.Basic description={t('organizer.memo.error')} />);
      listInvalidateQuery();
    }
  };

  const listInvalidateQuery = () => {
    void queryClient.invalidateQueries({
      queryKey: [getMemoListQueryId],
    });
  };
  return (
    <li
      className={clsx(
        'flex flex-col justify-between my-2 cursor-pointer rounded-md',
        memoHeightStyle,
        MemoColorClassNames[memo.color],
      )}
      onClick={() => {
        void handleOnClick();
      }}
    >
      <div className="mx-2 mt-2 overflow-hidden">
        <div
          ref={textRef}
          className="memo-editor-container overflow-hidden whitespace-normal break-words pointer-events-none"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: lineNo,
          }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(displayContent),
          }}
        />
      </div>
      <div className="flex justify-between items-center mx-2">
        <p>{formatDate({ format: defaultTimeFormat, date: memo.updatedAt })}</p>
        <IconButton title="" onClick={handleClickStar}>
          <StarSolidIcon
            width={20}
            height={20}
            color={memo.isFavorite ? favoriteTrueColor : favoriteFalseColor}
          />
        </IconButton>
      </div>
    </li>
  );
}

export default MemoItem;
