import { PropsWithChildren } from 'react';
import { ActionItemInfo, ActionItemType, SizeType } from '../types';
import { clsx } from 'clsx';
import * as Tooltip from '@daouoffice/ui/lib/labs/EnhancedTooltip';

const getActionItemSize = (sizeType: SizeType, itemType: ActionItemType) => {
  if (sizeType === 'tiny') {
    return [16, 'ic_small', 'small'];
  } else if (sizeType === 'small') {
    return [18, 'ic_small', 'small'];
  } else if (sizeType === 'medium' || itemType === 'more') {
    return [24, 'ic_medium', 'medium'];
  } else if (sizeType === 'large') {
    return [36, 'ic_large', 'large'];
  } else {
    return [48, 'ic_xl', undefined];
  }
};
interface Props extends ActionItemInfo {
  className?: string;
}

function ToolItem({
  children,
  uid,
  itemType,
  name,
  size,
  url,
  onClick,
  className,
}: PropsWithChildren<Props>) {
  const [, buttonSizeClassName] = getActionItemSize(size, itemType);

  return (
    <li key={uid} className={className}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>
          <a
            className={clsx('btn_icon', buttonSizeClassName)}
            href={url}
            onClick={onClick}
            key={uid}
          >
            {children}
          </a>
        </Tooltip.Trigger>
        <Tooltip.Content side="left" sideOffset={15}>
          {name}
        </Tooltip.Content>
      </Tooltip.Root>
    </li>
  );
}

export default ToolItem;
