import { PropsWithChildren } from 'react';
import { Provider } from './Context';

export interface Props {
  /** @property 앱 코드 */
  appCode?: string;
  /** @property className */
  className?: string;
}

export function Root({ appCode, children }: PropsWithChildren<Props>) {
  return <Provider appCode={appCode}>{children}</Provider>;
}

export default Root;
