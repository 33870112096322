import { IconContainer } from '@daouoffice/ui/lib/icons/dop/IconContainer';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import { useTranslation } from '../../../lib/i18n/client/useTranslation';
import { checkSimpleCode } from '../api/checkSimpleCode';
import { ResponseBody } from '../api/types';
import { SimpleCodeFormPrimitives } from '../index';
import { useActionsContext, useValuesContext } from '../primitives/Context';
import { clientType } from '../types';
import { getOnlyNumber } from '../utils';

export interface Props {
  /** @property title 텍스트 */
  title?: string;
  /** @property className */
  className?: string;
  /** @property client type(web | chat) */
  type?: clientType;
  /** @property onSuccess 함수 */
  onSuccess?: () => void;
}

export function SimpleCodeChecker({
  title,
  className,
  type,
  onSuccess,
}: Props) {
  const { t } = useTranslation();
  const { simpleCode, errorMessage, appCode } = useValuesContext();
  const { setSimpleCode, setErrorMessage } = useActionsContext();

  const enterKeyHandler = (e: React.KeyboardEvent<HTMLInputElement>) =>
    e.key === 'Enter' && checkHandler();

  const checkHandler = () => handleOnCheckSimpleCode();

  const handleOnCheckSimpleCode = () => {
    void checkSimpleCode(simpleCode, appCode ?? '')
      .then((res: ResponseBody<string>) => {
        setErrorMessage(res?.message ?? '');
        res?.data && onSuccessHandler();
      })
      .catch((e: Error) => {
        setErrorMessage(e.message);
      });
  };

  useEffect(() => {
    simpleCode.length == 4 && checkHandler();
  }, [simpleCode]);

  const setErrorMessageByTextChanged = (text: string) => {
    text.length > 4
      ? setErrorMessage(t('simplecode.error.max.length'))
      : isNaN(Number(text))
        ? setErrorMessage(t('simplecode.error.not.number'))
        : setErrorMessage('');
  };

  const onSuccessHandler = () => {
    onSuccess && onSuccess();
  };

  return (
    <SimpleCodeFormPrimitives.Contents className={className}>
      {type === 'chat' ? (
        <>
          <SimpleCodeFormPrimitives.Title
            title={t('screenlock.title')}
            className="mb-4 text-3xl pb-16"
          />
          <IconContainer size={50}>
            <LockClosedIcon />
          </IconContainer>
          <SimpleCodeFormPrimitives.InfoMessage
            message={t('simplecode.placeholder.simple.code')}
            className="mb-4 pt-4 text-primary"
          />
        </>
      ) : (
        <>
          <SimpleCodeFormPrimitives.Title
            title={`${title ? `${title} ` : ''}` + t('simplecode.check.title')}
            className="mb-4"
          />
          <SimpleCodeFormPrimitives.InfoMessage
            message={t('simplecode.check.info.link.text')}
            linkUrl="/secure-manage/"
            className="mb-4"
          />
        </>
      )}
      <SimpleCodeFormPrimitives.PinField
        value={simpleCode}
        // placeholder={t('simplecode.placeholder.simple.code')}
        className="mb-4"
        onTextChange={(text) => {
          setErrorMessageByTextChanged(text);
          text.length <= 4 && setSimpleCode(getOnlyNumber(text));
        }}
        onKeyPress={enterKeyHandler}
      />
      <SimpleCodeFormPrimitives.ErrorMessage
        message={errorMessage ?? ''}
        className="mb-4"
      />

      <SimpleCodeFormPrimitives.ActionButton
        title={
          type === 'chat'
            ? t('screenlock.unlock')
            : `${title ? `${title} ` : ''}` + t('simplecode.check.button.title')
        }
        handleOnAction={checkHandler}
      />
    </SimpleCodeFormPrimitives.Contents>
  );
}

export default SimpleCodeChecker;
