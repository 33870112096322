import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { AppInfo } from './types';

export interface AccessInfo {
  appId?: string;
  appUrl?: string;
  appName?: string;
  accessTime: Date;
}

const sessionStorage = createJSONStorage<AppInfo>(() => window.sessionStorage);

// 접속 정보 atomWithStorage 정의.
export const accessInfoAtom = atomWithStorage<AccessInfo[]>('accessInfo', []);

// 새로 고침 후에도, 앱 정보를 유지하기 위한 atomWithStorage 정의.
export const appInfoAtom = atomWithStorage<AppInfo>(
  'appInfo',
  {},
  sessionStorage,
);
