import { Avatar } from '@daouoffice/ui/lib/foundation/Avatar';
import { useEffect, useState } from 'react';
import { getRandomColor } from '../../../lib/utils/color';

export interface Props {
  id?: number;
  name: string;
  faviconUrl?: string;
  size: number;
  className?: string;
}

function LinkItem({ id, name, faviconUrl, size, className = '' }: Props) {
  const [isImgError, setImgErrorState] = useState(false);
  const [itemColor, setItemColor] = useState(getRandomColor(id));

  useEffect(() => {
    setImgErrorState(false);
    setItemColor(getRandomColor(id));
  }, [setItemColor, id]);

  const handleImageError = () => {
    setImgErrorState(true);
  };
  return faviconUrl && !isImgError ? (
    <img
      width={size}
      height={size}
      src={faviconUrl}
      onError={handleImageError}
      alt=""
    />
  ) : (
    <Avatar
      type="circle"
      alt={name}
      size={size}
      backgroundColor={itemColor}
      className={className}
    />
  );
}

export default LinkItem;
