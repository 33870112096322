import { useTranslation } from 'react-i18next';

export function NoWorksTemplate() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-[400px]">
      <p className="text-[15px]">{t('organizer.empty.works')}</p>
      <p className="text-[13px] text-gray-300">
        {t('organizer.empty.works.desc')}
      </p>
    </div>
  );
}

export default NoWorksTemplate;
