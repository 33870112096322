'use client';
import { type IUserAppInfo } from '@daouoffice/ui/lib/service/AppsDock';
import { clsx } from 'clsx';
import Link from 'next/link';
import * as React from 'react';

export interface Props {
  data: IUserAppInfo;
  activated?: boolean;
  as?: JSX.ElementType;
}

export function ItemButton({ data, activated, as = 'div' }: Props) {
  const Container = as;

  const el = (
    <Container
      className={clsx('menu_list', {
        active: activated,
      })}
    >
      <Link className="menu" href={data.url}>
        <i
          className="icon ic_medium"
          dangerouslySetInnerHTML={{ __html: getAppIcon(data) }}
        />
        <span className="txt">{data.name}</span>
      </Link>
    </Container>
  );

  return React.isValidElement(el) ? el : null;
}

/**
 * (임시) 추후 관리앱 (Managed App)의 아이콘 관리방식이 결정되면 로직이 변경될 수 있음.
 * @param item
 * @returns
 */
function getAppIcon(item: IUserAppInfo): string {
  return item.icon as string;
}

export default ItemButton;
