import { clsx } from 'clsx';
import { AppType } from '../type';
import { useTranslation } from 'react-i18next';
import { useContext } from '../../../Context';
import {
  CreateAppShortcutInput,
  mapper,
  postAppShortcut,
} from '../api/createShortcutQuery';
import { useToast, Templates } from '../../../../Toast';
import { QUERY_KEY as getOrganizerQueryId } from '../../../apis/getOrganizerQuery';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import LinkItem from '../../../LinkItem';
import { SizeType } from '../../../types';

const getActionItemSize = (sizeType: SizeType) => {
  if (sizeType === 'tiny') {
    return [16, 'ic_small', 'small'];
  } else if (sizeType === 'small') {
    return [18, 'ic_small', 'small'];
  } else if (sizeType === 'medium') {
    return [24, 'ic_medium', 'medium'];
  } else if (sizeType === 'large') {
    return [36, 'ic_large', 'large'];
  } else {
    return [48, 'ic_xl', undefined];
  }
};

interface Props {
  type: string;
  appItem: AppType;
}

export function AppItem({ type, appItem }: Props) {
  const { t } = useTranslation();
  const ctx = useContext();
  const queryClient = useQueryClient();

  const { info } = useToast();

  const [sizeNumber] = getActionItemSize(appItem.size);

  const handleOnSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [getOrganizerQueryId],
    });
    info(<Templates.Basic description={t('organizer.toast.add')} />);
  };

  const postAppShortcutMutaion = useMutation({
    mutationFn: (appData: CreateAppShortcutInput) => postAppShortcut(appData),
    onSuccess: () => {
      handleOnSuccess();
    },
    onError(error) {
      console.log(error);
    },
  });

  const shortcutListByType = ctx.shortcutItems.filter((item) => {
    return item.appType === type;
  });

  const isAdded = shortcutListByType.some((item) => {
    return item.itemId === appItem.id;
  });

  const handleClickAdd = (appItem: AppType) => {
    postAppShortcutMutaion.mutate(mapper(appItem, type));
  };

  return (
    <li className="flex items-center py-3">
      <LinkItem
        id={appItem.id}
        name={appItem.name}
        size={sizeNumber as number}
        faviconUrl={appItem.thumbnail}
      />
      <div className="flex-grow mx-3 min-w-[170px] max-w-[170px]">
        <p className="text-[16px] font-[500] overflow-hidden whitespace-nowrap text-ellipsis">
          {appItem.name}
        </p>
        <div className="text-[13px] text-gray-500 line-clamp-2">
          {appItem.desc}
        </div>
      </div>
      <div className="flex justify-center float-right min-w-[50px] w-[80px]">
        <button
          className={clsx(
            'w-[50px] py-1 px-2 text-center rounded-md',
            isAdded ? 'bg-white text-black' : 'bg-black text-white',
          )}
          disabled={isAdded}
          onClick={() => handleClickAdd(appItem)}
        >
          {isAdded ? t('organizer.added.button') : t('organizer.add.button')}
        </button>
      </div>
    </li>
  );
}

export default AppItem;
