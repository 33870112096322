import { Button } from '@daouoffice/ui/lib/foundation/Button';
import { ReactNode } from 'react';
import { useContext } from './Context';

export function Trigger({ children }: { children: ReactNode }) {
  const { setCardOpenState } = useContext();
  return (
    <Button
      title=""
      className="btn_profile"
      onClick={() => setCardOpenState(true)}
    >
      {children}
    </Button>
  );
}
