import { IconContainer } from '../IconContainer';
import type { IconCommonProps } from '../types';

/**
 * Descend (16x16) 아이콘
 * @param props
 * @returns
 */
export function DescendIcon(props: IconCommonProps) {
  return (
    <IconContainer {...props} viewBox="0 0 15 14" size={15}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1108 4.44088C3.31583 4.23585 3.64824 4.23585 3.85326 4.44088L7.85703 8.44465L11.8608 4.44088C12.0658 4.23585 12.3982 4.23585 12.6033 4.44088C12.8083 4.6459 12.8083 4.97832 12.6033 5.18334L8.22826 9.55834C8.02324 9.76337 7.69083 9.76337 7.4858 9.55834L3.1108 5.18334C2.90577 4.97832 2.90577 4.6459 3.1108 4.44088Z"
        fill="#444444"
      />
    </IconContainer>
  );
}

export default DescendIcon;
