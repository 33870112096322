import LinkItem from '../../LinkItem';
import { ShortcutItemInfo } from '../../types';

interface Props {
  additionalItemList?: ShortcutItemInfo[];
}

function AdditionalItemList({ additionalItemList: additionalItemList }: Props) {
  return (
    <ul>
      {additionalItemList?.map((item) => (
        <li key={item.uid + item.itemType}>
          <a href={item.url} target="_blank" rel="noreferrer">
            <LinkItem
              name={item.name}
              faviconUrl={item.icon}
              size={24}
              key={item.uid}
            />
            <span className="txt">{item.name}</span>
          </a>
        </li>
      ))}
    </ul>
  );
}

export default AdditionalItemList;
