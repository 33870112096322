import { clsx } from 'clsx';
import {
  KeyboardEvent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useActionsContext } from './Context';

export type TextFieldType = 'text' | 'password';

export interface Props {
  title?: string;
  value?: string;
  className?: string;
  onTextChange?: (text: string) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export function PinField({
  title,
  onKeyPress,
  className,
}: PropsWithChildren<Props>) {
  const PIN_LENGTH = 4;
  const EMPTY_PIN_VALUE = -1;

  const { setSimpleCode } = useActionsContext();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const [pin, setPin] = useState<number[]>(
    Array(PIN_LENGTH).fill(EMPTY_PIN_VALUE),
  );

  const removeValuesFromArray = (valuesArray: string[], value: string) => {
    const valueIndex = valuesArray.findIndex((entry) => entry === value);
    if (valueIndex === EMPTY_PIN_VALUE) {
      return;
    }
    valuesArray.splice(valueIndex, 1);
  };

  const changePinFocus = (pinIndex: number) => {
    const ref = inputRefs.current[pinIndex];
    ref && ref.focus();
  };

  const onPinChanged = (pinEntry: string, index: number) => {
    const newPin = [...pin];
    const pinEntryNumber = Number(pinEntry);
    !isNaN(pinEntryNumber) && (newPin[index] = pinEntryNumber);
    setPin(newPin);
  };

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const valuesArray = event.target.value.split('');
    removeValuesFromArray(valuesArray, pin.join(''));
    const value = valuesArray.pop();
    if (!value) {
      return;
    }

    const pinNumber = Number(value.trim());
    if (isNaN(pinNumber) || value.length === 0) {
      return;
    }

    if (pinNumber >= 0 && pinNumber <= 9) {
      onPinChanged(value.trim(), index);
      index < PIN_LENGTH - 1 && changePinFocus(index + 1);
    }
  };

  const onKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    onKeyPress && onKeyPress(event);
    if (event.nativeEvent.code === 'Backspace') {
      onPinChanged(`${EMPTY_PIN_VALUE}`, index);
      changePinFocus(index - 1);
      return;
    } else if (pin[index] === undefined) {
      changePinFocus(index - 1);
    }
  };

  useEffect(() => {
    if (pin.filter((entry) => entry === EMPTY_PIN_VALUE).length > 0) {
      setSimpleCode('');
    } else setSimpleCode(pin.join(''));
  }, [pin, setSimpleCode, EMPTY_PIN_VALUE]);

  return (
    <div className={clsx('flex justify-center', className)}>
      {title && <span className="font-bold">{title}</span>}
      <div className="flex justify-evenly w-[248px]">
        {Array.from({ length: PIN_LENGTH }).map((_, index) => (
          <input
            className="w-10 h-10 border border-solid border-[#ddd] rounded-md text-black text-center caret-transparent focus:border-black"
            key={index}
            type="password"
            ref={(el) => {
              el && (inputRefs.current[index] = el);
            }}
            onKeyDown={(e) => onKeyDown(e, index)}
            onChange={(e) => onChange(e, index)}
            value={pin[index] === EMPTY_PIN_VALUE ? '' : pin[index]}
          />
        ))}
      </div>
    </div>
  );
}

export default PinField;
