import * as React from 'react';

export interface IContextValues {
  readonly openNodes: Set<string>;
}

export interface IContextActions {
  setOpenNodes: React.Dispatch<React.SetStateAction<Set<string>>>;
}

export const ValuesContext = React.createContext<IContextValues>({
  openNodes: new Set(),
});

export const ActionsContext = React.createContext<IContextActions>({
  setOpenNodes: () => undefined,
});

export function Provider({ children }: { children: React.ReactNode }) {
  const [openNodes, setOpenNodes] = React.useState<Set<string>>(new Set());

  const contextValues = {
    openNodes,
  };

  const contextActions = {
    setOpenNodes,
  };

  return (
    <ActionsContext.Provider value={contextActions}>
      <ValuesContext.Provider value={contextValues}>
        {children}
      </ValuesContext.Provider>
    </ActionsContext.Provider>
  );
}

export const useMenuTreeValuesContext = () => React.useContext(ValuesContext);
export const useMenuTreeActionsContext = () => React.useContext(ActionsContext);

export const useMenuTreeContext = () => {
  const values = useMenuTreeValuesContext();
  const actions = useMenuTreeActionsContext();

  return {
    ...values,
    ...actions,
  };
};
