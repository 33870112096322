import { useEffect, useMemo, useRef, useState } from 'react';
import { useMemoContext } from '../Context';
import {
  useStack,
  useStackMethod,
} from '@daouoffice/ui/lib/foundation/Stacker';
import { DeleteIcon } from '@daouoffice/ui/lib/icons/dop/24';
import * as Popover from '@daouoffice/ui/lib/foundation/Popover';
import { Button, IconButton, useDebounceWithFlush } from '@daouoffice/ui';
import { PreviousIndicatorIcon } from '@daouoffice/ui/lib/icons/dop/16';

import MemoEditor from './Editor';
import DeleteMemoForm from './DeleteMemoForm';
import { useToast, Templates } from '../../../../Toast';

import { createMemo } from '../apis/createMemo';
import { deleteMemo } from '../apis/deleteMemo';
import { updateMemo } from '../apis/updateMemo';
import * as getMemoQuery from '../apis/getMemo';
import * as getMemoListQuery from '../apis/getMemoList';
import * as getMemoSearchQuery from '../apis/getMemoSearch';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import type { OrganizerMemoPages } from '..';

export function OrganizerMemoEdit() {
  const { stack } = useStack<OrganizerMemoPages>();
  const { pop } = useStackMethod<OrganizerMemoPages>();
  const canPop = stack.length > 1;

  const { info } = useToast();
  const { t } = useTranslation();
  const firstLoadedRef = useRef(false);
  const queryClient = useQueryClient();

  const {
    memoContext: { memoId, content, requiredSave, color },
    setMemoContext,
  } = useMemoContext();

  const autoSave = useDebounceWithFlush(
    () => {
      if (!memoId) {
        createMemoMutation.mutate();
      } else {
        updateMemoMutation.mutate();
      }
    },
    1000 * 60 * 5,
  );

  useEffect(() => {
    if (!firstLoadedRef.current) {
      firstLoadedRef.current = true;
    } else {
      autoSave();
    }
    return () => {
      autoSave.flush();
    };
  }, [content, color]);

  const deleteMemoMutation = useMutation({
    onSuccess: () => {
      info(<Templates.Basic description={t('organizer.memo.toast.deleted')} />);
      setDeleteFormOpen(false);
      handlePop();
    },
    onError: (e) => {
      setDeleteFormOpen(false);
      info(<Templates.Basic description={e.message} />);
      handlePop();
    },
    mutationFn: async () => await deleteMemo(memoId!),
  });

  const [deleteFormOpen, setDeleteFormOpen] = useState(false);

  const updateMemoMutation = useMutation({
    mutationFn: async () => await updateMemo({ id: memoId!, content, color }),
    onSuccess: (data) => {
      info(<Templates.Basic description={t('organizer.memo.toast.saved')} />);
      setMemoContext((prev) => ({
        ...prev,
        updatedAt: data.data.updatedAt,
        requiredSave: false,
      }));
      void queryClient.invalidateQueries({
        queryKey: [getMemoQuery.QUERY_KEY, memoId],
      });
    },
    onError: (e) => {
      info(<Templates.Basic description={e.message} />);
      handlePop();
    },
  });

  const createMemoMutation = useMutation({
    mutationFn: async () => await createMemo({ content, color }),
    onSuccess: (data) => {
      setMemoContext((prev) => ({
        ...prev,
        memoId: data.data.id,
        updatedAt: data.data.updatedAt,
        requiredSave: false,
      }));
      info(<Templates.Basic description={t('organizer.memo.toast.saved')} />);
    },
    onError: (e) => {
      <Templates.Basic description={e.message} />;
    },
  });

  const handleOnClickSave = () => {
    autoSave.flush();
    if (!memoId) {
      createMemoMutation.mutate();
    } else {
      updateMemoMutation.mutate();
    }
  };

  const handlePop = () => {
    void queryClient.invalidateQueries({
      queryKey: [getMemoQuery.QUERY_KEY, memoId],
    });
    void queryClient.invalidateQueries({
      queryKey: [getMemoSearchQuery.QUERY_KEY],
    });
    void queryClient.invalidateQueries({
      queryKey: [getMemoListQuery.QUERY_KEY],
    });
    setMemoContext((prev) => ({
      ...prev,
      memoId: null,
      content: '',
      updatedAt: '',
      requiredSave: false,
      isFavorite: false,
      color: 'yellow',
    }));

    pop();
  };

  const handleOnClickPrev = () => {
    if (requiredSave && !confirm(t('organizer.setting.alert.cancel'))) {
      return;
    }
    handlePop();
  };

  const prev = useMemo(() => {
    return (
      canPop && (
        <IconButton
          title="prev"
          onClick={() => handleOnClickPrev()}
          className="mr-2"
        >
          <PreviousIndicatorIcon />
        </IconButton>
      )
    );
  }, [canPop, requiredSave]);

  const deleteButton = useMemo(() => {
    return (
      memoId && (
        <Popover.Root open={deleteFormOpen} onOpenChange={setDeleteFormOpen}>
          <Popover.Trigger>
            <IconButton title="Delete" as="div">
              <DeleteIcon />
            </IconButton>
          </Popover.Trigger>
          <Popover.Content
            isPortal
            side="bottom"
            align="end"
            alignOffset={-65}
            sideOffset={10}
          >
            <DeleteMemoForm
              onClickCancel={() => {
                setDeleteFormOpen(false);
              }}
              onClickConfirm={() => deleteMemoMutation.mutate()}
            />
          </Popover.Content>
        </Popover.Root>
      )
    );
  }, [memoId, deleteFormOpen]);

  const updateButton = useMemo(() => {
    return (
      <Button
        shape="rect"
        size="medium"
        title={memoId ? t('organizer.memo.update') : t('organizer.memo.save')}
        disabled={!requiredSave}
        className="align-bottom"
        onClick={handleOnClickSave}
      />
    );
  }, [memoId, requiredSave]);

  return (
    <div className="organizer_cont card wrap_card open" id="organizer_cont">
      <div className="wrap_organizer_cont flex flex-col h-full">
        <div className="wrap_organizer_header" style={{ alignItems: 'center' }}>
          {prev}

          <span className="text-[16px] flex-1">
            {t('organizer.memo.write')}
          </span>

          {deleteButton}
          {updateButton}
        </div>
        <div className="p-[0_25px_32px] gap-4 flex flex-col h-[calc(100%-88px)] max-h-[calc(100%-88px)]">
          {<MemoEditor />}
        </div>
      </div>
    </div>
  );
}

export default OrganizerMemoEdit;
