export const CONFIG_APP_URLS = {
  prefix: '/config',
  DATA_MANAGEMENT: {
    prefix: '/data-manage',
    CURRENT_DATA_USAGE: '/config/data-manage/usage/current',
    DATA_REQUEST_MANAGEMENT: '/config/data-manage/usage/request',
    SET_USAGE_NOTIFICATION: '/config/data-manage/usage/notification',
    UPLOAD_POLICY_MANAGEMENT: '/config/data-manage/upload-policy/policy',
    UPLOAD_DATA_MANAGEMENT: '/config/data-manage/upload-data/data',
    DEPT_DATA_MANAGEMENT: '/config/data-manage/depts/data',
    DELETED_DEPT_DATA_MANAGEMENT: '/config/data-manage/depts/delete',
    MEMBER_DATA: '/config/data-manage/member/data',
    BACK_UP_DATA: '/config/data-manage/back-up/data',
  },
};
