'use client';

import {
  Provider,
  ProviderProps,
} from '@daouoffice/ui/lib/labs/EnhancedTooltip';
import type { PropsWithChildren } from 'react';

export function EnhancedTooltipProvider({
  children,
  ...props
}: PropsWithChildren<ProviderProps>) {
  return <Provider {...props}>{children}</Provider>;
}

export default EnhancedTooltipProvider;
