import { Root } from './Root';
import { AppMenu } from './AppMenu';
import { Contents } from './Contents';
import { ServiceMap } from './ServiceMap';
import { useGlobalConfigStore } from '../store/globalConfigStore';

export const GlobalConfigPrimitives = {
  Root,
  AppMenu,
  Contents,
  ServiceMap,
  useGlobalConfigStore,
};
