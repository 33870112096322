import { useStackMethod } from '@daouoffice/ui/lib/foundation/Stacker';
import type { OrganizerMemoPages } from '../index';
import { IconButton } from '@daouoffice/ui';
import { SearchIcon } from '@daouoffice/ui/lib/icons/dop/24';
import MemoMainContent from './MemoMainContent';
import { useTranslation } from '../../../../../lib/i18n/client/useTranslation';
import { Suspense } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import * as getMemoRecentlySearchList from '../apis/getMemoRecentlySearchList';

function OrganizerMemoMain() {
  const { push } = useStackMethod<OrganizerMemoPages>();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const handleClickSearchBtn = () => {
    push('search');

    void queryClient.invalidateQueries({
      queryKey: [getMemoRecentlySearchList.QUERY_KEY],
    });
  };

  return (
    <div className="organizer_cont card wrap_card open" id="organizer_cont">
      <div className="wrap_organizer_cont">
        <div className="wrap_organizer_header items-center">
          <h3 className="heading_medium bold">{t('organizer.memo.title')}</h3>
          <IconButton title="" onClick={handleClickSearchBtn}>
            <SearchIcon />
          </IconButton>
        </div>
        <div className="wrap_organizer_list">
          <Suspense fallback={<div>Loading...</div>}>
            <MemoMainContent />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export default OrganizerMemoMain;
